import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { prop } from "ramda";
import { useCountryRestriction } from "../../countryRestrictor";
import { useTranslation } from "react-i18next";

const AddressForm = ({ address, customer, show, onSave, onCancel }) => {
  const { availableCountries } = useCountryRestriction();
  const [validated, setValidated] = useState(false);
  const [t] = useTranslation();

  useEffect(() => setValidated(false), [show]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      onSave(form);
    }
  };

  return (
    <Modal show={show} onHide={onCancel}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{t("addressForm.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridFirst">
              <Form.Label>{t("addressForm.firstName")}*</Form.Label>
              <Form.Control
                name="payer.name.given_name"
                defaultValue={customer.first_name}
                required
                type="text"
                placeholder="Jane"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLast">
              <Form.Label>{t("addressForm.lastName")}*</Form.Label>
              <Form.Control
                name="payer.name.surname"
                defaultValue={customer.last_name}
                required
                type="text"
                placeholder="Doe"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>{t("addressForm.email")}*</Form.Label>
              <Form.Control
                name="payer.email_address"
                defaultValue={customer.email_address}
                required
                type="email"
                placeholder="jane.doe@gmail.com"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label>{t("addressForm.line1")}*</Form.Label>
              <Form.Control
                name="shipping.address.address_line_1"
                defaultValue={prop("address_line_1", address)}
                required
                placeholder="Friedensallee 123"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAddress2">
              <Form.Label>{t("addressForm.line2")}</Form.Label>
              <Form.Control
                name="shipping.address.address_line_2"
                defaultValue={prop("address_line_2", address)}
                placeholder="optional"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {prop("address_line_3", address) && (
              <Form.Group as={Col} controlId="formGridAddress3">
                <Form.Label>{t("addressForm.line3")}</Form.Label>
                <Form.Control
                  name="shipping.address.address_line_3"
                  defaultValue={prop("address_line_3", address)}
                  placeholder="optional"
                />
              </Form.Group>
            )}
            {prop("address_line_4", address) && (
              <Form.Group as={Col} controlId="formGridAddress4">
                <Form.Label>{t("addressForm.line4")}</Form.Label>
                <Form.Control
                  name="shipping.address.address_line_4"
                  defaultValue={prop("address_line_4", address)}
                  placeholder="optional"
                />
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>{t("addressForm.area2")}</Form.Label>
              <Form.Control
                name="shipping.address.admin_area_2"
                defaultValue={prop("admin_area_2", address)}
                required
                placeholder="Berlin"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>{t("addressForm.area1")}</Form.Label>
              <Form.Control
                name="shipping.address.admin_area_1"
                defaultValue={prop("admin_area_1", address)}
                placeholder="optional"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>{t("addressForm.zip")}</Form.Label>
              <Form.Control
                required
                name="shipping.address.postal_code"
                defaultValue={prop("postal_code", address)}
                placeholder="123456"
              />
            </Form.Group>
          </Form.Row>
          {prop("admin_area_3", address) && (
            <Form.Group as={Col} controlId="formGridArea3">
              <Form.Label>{t("addressForm.area3")}</Form.Label>
              <Form.Control
                name="shipping.address.admin_area_3"
                defaultValue={prop("admin_area_3", address)}
                required
              />
            </Form.Group>
          )}
          <Form.Group controlId="formGridCountryCode">
            <Form.Label>{t("addressForm.country")}</Form.Label>
            <Form.Control
              as="select"
              name="shipping.address.country_code"
              defaultValue={prop("country_code", address)}
            >
              {Object.keys(availableCountries).map((code) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={onCancel}>
            {t("addressForm.btnClose")}
          </Button>
          <Button variant="primary" type="submit">
            {t("addressForm.btnSubmit")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddressForm;
