import React, { useEffect, useState } from "react";
import { trackEvent } from "../googleAnalytics";
import { Trans } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import useScript from "../../hooks/useScript";

const isReactSnap = navigator.userAgent === "ReactSnap";
const SHOULD_HIDE_BANNER = isReactSnap;
const SCRIPT_SRC = "https://www.gofundme.com/static/js/embed.js";

const DonateBanner = () => {
  const [hideBanner, setHideBanner] = useState(true);

  const status = useScript(SCRIPT_SRC);

  useEffect(() => {
    setHideBanner(SHOULD_HIDE_BANNER);
    if (!SHOULD_HIDE_BANNER) {
      trackEvent({
        category: "DonateBanner",
        action: "Impression",
        nonInteraction: true,
      });
    }
  }, [status]);

  if (hideBanner) return null;

  return (
    <div className="donate-banner">
      <div className="donate-banner-content">
        <Container>
          <Row className="donate-banner-row">
            <Col className="donate-banner-col message" lg="auto">
              <Trans i18nKey="donateBanner.message">
                Donate now to our project and our NGO | Support for affected
                people
              </Trans>
            </Col>
            <Col className="donate-banner-col">
              <div
                className="gfm-embed"
                data-url="https://www.gofundme.com/f/rxyxr/widget/medium?sharesheet=CAMPAIGN_PAGE"
              >
                <iframe
                  title="gfm"
                  width="100%"
                  height="213"
                  scrolling="no"
                  src={
                    "https://www.gofundme.com/f/rxyxr/widget/medium?sharesheet=CAMPAIGN_PAGE#:~:tcm-regime=GDPR&tcm-prompt=Hidden"
                  }
                  frameBorder="0"
                  className="gfm-embed-iframe"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default DonateBanner;
