import React, { useEffect, useState, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";
import * as faceapi from "face-api.js";
import { drawResult } from "../../helpers/canvas";
import { trackEvent } from "../googleAnalytics";

const ImageBox = ({ image }) => {
  const [detecting, setDetecting] = useState(true);
  const [canvasSize, setCanvasSize] = useState();
  const canvasRef = useRef();
  const imageRef = useRef();

  const onImageLoad = () => {
    if (imageRef.current) {
      setCanvasSize({
        width: imageRef.current.width,
        height: imageRef.current.height,
      });
    }
  };

  useEffect(() => {
    if (!canvasSize || canvasSize.width === 0) return () => {};
    const fetchModel = async () => {
      canvasRef.current.height = canvasSize.height;
      canvasRef.current.width = canvasSize.width;

      if (!faceapi.nets.tinyFaceDetector.isLoaded) {
        await faceapi.nets.tinyFaceDetector.loadFromUri("/face-verification");
      }

      const detections = await faceapi.detectSingleFace(
        imageRef.current,
        new faceapi.TinyFaceDetectorOptions()
      );

      if (detections) {
        const result = faceapi.resizeResults(detections, canvasSize);
        drawResult({ canvas: canvasRef.current, result });
      }

      trackEvent({
        category: "Order",
        action: "Face Detect",
        value: detections ? 1 : 0,
        nonInteraction: true,
      });

      setDetecting(false);
    };
    fetchModel();
  }, [canvasSize]);

  return (
    <div className="image-box">
      <img src={image} onLoad={onImageLoad} ref={imageRef} alt="" />
      <canvas style={canvasSize} ref={canvasRef}></canvas>
      {detecting && (
        <div className="absolute-centered text-white">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default ImageBox;
