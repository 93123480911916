import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import format from 'date-fns/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const MissionHead = ({ mission }) => {
  const [t] = useTranslation()
  return (
    <div className="mission-head py-5">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={10} lg={8}>
            <Row>
              <Col className="d-flex flex-column">
                <Row className="flex-fill align-items-center">
                  <Col xs={6} md={3}>
                    {t('missionHead.createdAt')}
                  </Col>
                  <Col>
                    {format(new Date(mission.created_at), 'dd.MM.yyyy - HH:mm')}{' '}
                  </Col>
                </Row>
                <Row className="flex-fill align-items-center">
                  <Col xs={6} md={3}>
                    {t('missionHead.payedWith')}
                  </Col>
                  <Col>
                    <FontAwesomeIcon icon={['fab', 'paypal']} size="2x" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MissionHead
