import { lazy } from "react";

const About = lazy(() => import("./screens/about"));
const FAQ = lazy(() => import("./screens/faq"));
const GetInTouch = lazy(() => import("./screens/getInTouch"));
const Insights = lazy(() => import("./screens/insights"));
const Legal = lazy(() => import("./screens/legal"));
const ServiceDescription = lazy(() => import("./screens/serviceDescription"));
const Privacy = lazy(() => import("./screens/privacy"));
const TermsOfService = lazy(() => import("./screens/termsOfService"));
const Missions = lazy(() => import("./screens/missions"));
const Report = lazy(() => import("./screens/missions/report"));
const MissionsShow = lazy(() => import("./screens/missions/show"));
const OurMission = lazy(() => import("./screens/ourMission"));
const Start = lazy(() => import("./screens/start"));
const WorkWithUs = lazy(() => import("./screens/workWithUs"));
const OpenPositions = lazy(() => import("./screens/openPositions"));
const Donate = lazy(() => import("./screens/donate"));

export default [
  {
    key: "about",
    Component: About,
  },
  {
    key: "workWithUs",
    Component: WorkWithUs,
  },
  {
    key: "serviceDescription",
    Component: ServiceDescription,
  },
  {
    key: "terms",
    Component: TermsOfService,
  },
  {
    key: "privacy",
    Component: Privacy,
  },
  {
    key: "faq",
    Component: FAQ,
  },
  {
    key: "contact",
    Component: GetInTouch,
    customHeader: true,
  },
  {
    key: "insights",
    Component: Insights,
  },
  {
    key: "openPositions",
    Component: OpenPositions,
  },
  {
    key: "donate",
    Component: Donate,
  },
  {
    key: "legal",
    Component: Legal,
  },
  {
    key: "ourMission",
    Component: OurMission,
  },
  {
    key: "missions",
    Component: Missions,
  },
  {
    key: "missionsShow",
    /* eslint-disable-next-line react/display-name */
    Component: MissionsShow,
  },
  {
    key: "missionsReport",
    Component: Report,
  },
  {
    key: "report",
    Component: Report,
  },
  {
    key: "start",
    Component: Start,
    customHeader: true,
  },
];
