import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PayPal from "./payPal";
import HelpOver from "../helpOver";
import { Trans, useTranslation } from "react-i18next";
import usePrice from "../../hooks/usePrice";
import ssl from "../../images/ssl.png";

import sepa from "../../images/payment/sepa.svg";
import mastercard from "../../images/payment/mastercard.svg";
import visa from "../../images/payment/visa.svg";
import amex from "../../images/payment/amex.svg";
import maestro from "../../images/payment/maestro.svg";

const Feature = ({ children }) => (
  <li className="position-relative">
    <div className="feature-check">
      <FontAwesomeIcon fixedWidth icon="check"></FontAwesomeIcon>
    </div>
    {children}
  </li>
);

const PricingTable = ({ onAuthorize }) => {
  const [t] = useTranslation();
  const { formattedValue: price } = usePrice();
  const pricePopover = (
    <HelpOver
      id="why-we-cannot-be-free-service"
      content={t("pricingTable.helpOver")}
    >
      <small>{t("pricingTable.whyWeCharge")}</small>
    </HelpOver>
  );

  return (
    <div className="payment-box">
      <div className="price-teaser mb-4">
        <div className="price-box mb-2">
          <div className="price-row">{price}*</div>
          <div className="meta-row">{t("pricingTable.perSearch")}</div>
        </div>
        <div className="t-size-0 price-popover">{pricePopover}</div>
      </div>
      <div className="col-md-9 col-lg-8 mx-auto">
        <PayPal onAuthorize={onAuthorize} show={true} />
      </div>
      <div className="d-flex align-items-end mt-5 mb-2">
        <div className="text-center col">
          <div className="d-flex align-items-center justify-content-around mb-2">
            <img className="payment-logo" src={sepa} alt="" />
            <img className="payment-logo" src={mastercard} alt="" />
            <img className="payment-logo" src={visa} alt="" />
            <img className="payment-logo" src={amex} alt="" />
            <img className="payment-logo" src={maestro} alt="" />
          </div>
          <small>Supported payment options via PayPal</small>
        </div>
      </div>
      <small className="d-block text-center ml-1 fs-25">
        * {t("pricingTable.inclVat")}
      </small>
      <hr />
      <div className="features pt-4">
        <div className="row ssl d-flex align-items-center">
          <div className="col">
            <img src={ssl} alt="trusted ssl logo" />
          </div>
          <div className="col ssl-text">
            <Trans i18nKey="pricingTable.ssl">
              secure transmission through
              <br />
              100% SSL encryption
            </Trans>
          </div>
        </div>
        <ul className="features-list mt-1 pt-1">
          <Feature>{t("pricingTable.feature1")}</Feature>
          <Feature>{t("pricingTable.feature2")}</Feature>
          <Feature>{t("pricingTable.feature3")}</Feature>
          <Feature>{t("pricingTable.feature4")}</Feature>
        </ul>
      </div>
    </div>
  );
};

export default PricingTable;
