import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

export const languages = {
  en: {
    regions: [
      "en-US",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IN",
      "en-NZ",
      "en-PH",
      "en-ZA",
    ],
  },
  de: {
    regions: ["de-DE", "de-AT", "de-CH", "de-LI", "de-LU"],
  },
  es: {
    regions: ["es-ES", "ca-ES", "gl-ES", "eu-ES", "es-MX", "es-US"],
  },
  fr: {
    regions: ["fr-be", "fr-ca", "fr-ch", "fr-fr", "fr-lu", "fr-mc"],
  },
  pl: {
    regions: ["pl-PL"],
  },
  pt: {
    regions: ["pt-br", "pt-pt"],
  },
  zh: {
    regions: ["zh-cn", "zh-tw", "zh-hk"],
  },
};
export const defaultLanguage = "en";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ["common", "routes"],
    defaultNS: "common",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
