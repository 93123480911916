import React from 'react'
import Link from '../../components/link'

import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const [t] = useTranslation()
  return (
    <Container className="text-center">
      <h1>{t('screenNotFound.headline')}</h1>
      <p className="lead">{t('screenNotFound.subline')}</p>
      <p className="lead text-primary">{t('screenNotFound.lead')}</p>
      <Link to="start">{t('screenNotFound.btnBackToStart')}</Link>
    </Container>
  )
}

export default NotFound
