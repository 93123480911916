import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollHandler = ({ children }) => {
  const { hash, pathname } = useLocation()

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''))

      setTimeout(() => {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element
            ? window.scrollY + element.getBoundingClientRect().top
            : 0
        })
      }, 1000)
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash])

  return children
}

export default ScrollHandler
