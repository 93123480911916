import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import flag from "./flag.svg";
import Link from "../link";
import SocialMediaIcons from "../socialMediaIcons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation();
  return (
    <footer className="text-center mb-5">
      <Container>
        <Row>
          <Col>
            <ul className="list-inline">
              <li className="list-inline-item px-3">
                <Link to="about" className="text-secondary">
                  <span>{t("footer.ourCompany")}</span>
                </Link>
              </li>
              <li className="list-inline-item px-3">
                <Link to="workWithUs" className="text-secondary">
                  <span>{t("footer.workWithUs")}</span>
                </Link>
              </li>
              <li className="list-inline-item px-3">
                <Link to="donate" className="text-secondary">
                  <span>{t("footer.donate")}</span>
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <SocialMediaIcons />
          </Col>
        </Row>
        <Row className="mt-3 text-secondary">
          <Col>
            <div>
              <small>{t("footer.madeInGermany")}</small>
              <img
                alt={t("footer.germanFlagAlt")}
                className="flag ml-2"
                src={flag}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3 text-secondary">
          <Col className="d-flex flex-column flex-sm-row justify-content-center">
            <small className="mx-3">
              © {new Date().getFullYear()} deepXtech UG (haftungsbeschränkt)
            </small>
            <span>
              <Link className="mx-3 text-secondary" to="privacy">
                <small>{t("footer.privacy")}</small>
              </Link>
              <Link className="mx-3 text-secondary" to="serviceDescription">
                <small>{t("footer.serviceDescription")}</small>
              </Link>
              <Link className="mx-3 text-secondary" to="terms">
                <small>{t("footer.terms")}</small>
              </Link>
              <Link className="mx-3 text-secondary" to="legal">
                <small>{t("footer.legal")}</small>
              </Link>
            </span>
          </Col>
        </Row>
        <br />
      </Container>
    </footer>
  );
};

export default Footer;
