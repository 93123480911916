import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { isHelpline } from "../../hooks/useHelpline";

const isReactSnap = navigator.userAgent === "ReactSnap";

const TRACKING_ID = "G-7W22S1VG4Y";
// const OPTIMIZE_CONTAINER_ID = "GTM-WJT2K2M";
const IS_GA_ENABLED =
  !isReactSnap &&
  process.env.REACT_APP_ENVIRONMENT === "production" &&
  !isHelpline;

const logPageChange = (pathname, search = "") => {
  const page = pathname + search;
  const { location } = window;
  ReactGA.set({
    page,
    location: `${location.origin}${page}`,
  });
  ReactGA.send({ hitType: "pageview", page });
};

export const RouteTracker = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    logPageChange(pathname, search);
  }, [search, pathname]);

  return null;
};

export const init = (options = {}) => {
  if (IS_GA_ENABLED) {
    ReactGA.initialize(TRACKING_ID);
  }

  return IS_GA_ENABLED;
};

export const setDimension = (dimension, value) => {
  ReactGA.set({ [dimension]: value });
};

export const trackEvent = (event) => {
  if (!IS_GA_ENABLED) return;

  ReactGA.event(event);
};

export default {
  RouteTracker,
  init,
};
