import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Link from '../link'
import ButtonLink from '../buttonLink'

import logo from '../../images/logo.svg'
import { useTranslation } from 'react-i18next'

const Desktop = ({ links, trackClick }) => {
  const [t] = useTranslation()

  return (
    <header className="my-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={9} className="d-flex">
            <div className="flex-fill">
              <Link to="start" onClick={trackClick('logo')}>
                <img className="logo logo-dark" alt="Logo" src={logo} />
              </Link>
            </div>
            <div className="">
              {links.map(({ to, i18nKey }) => (
                <Link
                  key={to}
                  onClick={trackClick(to)}
                  className="px-4 py-2"
                  to={to}>
                  {t(i18nKey)}
                </Link>
              ))}
              <ButtonLink
                onClick={trackClick('start')}
                className="mx-4"
                to="start">
                {t('navigation.start')}
              </ButtonLink>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Desktop
