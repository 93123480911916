import React, { useState, useRef, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'

const DELAY = 300

const FullscreenSpinner = () => {
  const timeout = useRef()
  const [show, set] = useState(false)

  useEffect(() => {
    timeout.current = setTimeout(() => set(true), DELAY)

    return () => clearTimeout(timeout.current)
  }, [])

  return show ? (
    <div className="text-center fullscreen-overlay">
      <div className="absolute-centered">
        <Spinner animation="border" />
      </div>
    </div>
  ) : null
}

export default FullscreenSpinner
