import { useTranslation } from "react-i18next";
import { useLocation, matchPath } from "react-router-dom";

import { languages as languagesObject, defaultLanguage } from "../i18n";

const languages = Object.keys(languagesObject);

const useLanguageSelection = () => {
  let language;
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const routeMatch = matchPath(
    {
      path: "/:lang",
      end: false,
    },
    pathname
  );

  if (routeMatch) {
    const {
      params: { lang },
    } = routeMatch;

    const languageIndex = languages.indexOf(lang);
    const isDefaultLanguage = languageIndex === -1;
    language = isDefaultLanguage ? defaultLanguage : languages[languageIndex];
  } else {
    language = defaultLanguage;
  }

  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
};

export default useLanguageSelection;
