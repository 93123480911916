import React from 'react'
import Feature from '../feature'
import { Trans, useTranslation } from 'react-i18next'

const TheHow = () => {
  const [t] = useTranslation()
  return (
    <>
      <h2 className="text-center">{t('theHow.headline')}</h2>
      <hr className="w-25 mb-5" />
      <div className="">
        <Feature index={0} title={t('theHow.upload_title')} image="upload">
          <Trans i18nKey="theHow.upload_content">
            All you have to do is <strong>upload a picture</strong> of yourself
            with a few clicks
          </Trans>
        </Feature>
        <Feature
          index={1}
          title={t('theHow.anonymous_title')}
          image="anonymous">
          <Trans i18nKey="theHow.anonymous_content">
            Your uploaded image will <strong>not be saved</strong> and
            <strong>you will remain anonymous</strong>
            at all times.
          </Trans>
        </Feature>
        <Feature index={2} title={t('theHow.search_title')} image="search">
          <Trans i18nKey="theHow.search_content">
            We will search within <strong>millions of videos</strong> in a few
            seconds.
          </Trans>
        </Feature>
        <Feature index={3} title={t('theHow.precise_title')} image="precise">
          <Trans i18nKey="theHow.precise_content">
            Our AI-based facial recognition technology allows you to
            <strong>most precisely</strong> find the content you seek.
          </Trans>
        </Feature>
        <Feature index={4} title={t('theHow.help_title')} image="help">
          <Trans i18nKey="theHow.help_content">
            If you found yourself in a video
            <strong>we will help you</strong> to remove it.
          </Trans>
        </Feature>
      </div>
    </>
  )
}

export default TheHow
