import React from 'react'
import Helmet from 'react-helmet'
import { find, map, whereEq, without } from 'ramda'
import { useTranslation } from 'react-i18next'
import { getAllRoutesWhere } from '../../routes'
import { languages } from '../../i18n'

const basePath = 'https://amiinporn.org'

const locales = Object.keys(languages).reduce(
  (acc, cur) => acc.concat(languages[cur].regions),
  []
)

const localeWithUnderscore = str => str.replace(/-/, '_')

const Seo = props => {
  // const { routeKey } = props
  // Introduce page specific SEO later
  const routeKey = 'start'
  const { i18n, t } = useTranslation()

  const currentLanguage = i18n.language
  const locale = languages[currentLanguage].regions[0]
  const localesWithoutCurrent = map(
    localeWithUnderscore,
    without(locale, locales)
  )

  const languageRoutes = getAllRoutesWhere({ key: routeKey })
  const currentLanguageRoute = find(
    whereEq({ language: currentLanguage }),
    languageRoutes
  )
  const canonical = `${basePath}${currentLanguageRoute.path}`

  const title = t(`seo.title_${routeKey}`)
  const ogTitle = t(`seo.og_title_${routeKey}`)
  const description = t(`seo.description_${routeKey}`)

  const type = 'website'
  const image = `${basePath}/og-image.png`
  const twitterImage = `${basePath}/og-twitter.png`
  const width = 1200
  const height = 628

  return (
    <Helmet>
      <html prefix="og: http://ogp.me/ns#" lang={currentLanguage} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {<link rel="canonical" href={canonical} />}
      {image ? <link rel="image_src" href={image} /> : null}
      {image ? <meta itemProp="image" content={image} /> : null}
      <meta property="og:site_name" content="Am I In Porn?" />
      <meta property="og:title" content={ogTitle} />
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {canonical ? <meta property="og:url" content={canonical} /> : null}
      <meta property="og:locale" content={localeWithUnderscore(locale)} />
      {localesWithoutCurrent.map(locale => (
        <meta property="og:locale:alternate" key={locale} content={locale} />
      ))}

      <meta property="og:type" content={type} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={width} />
      <meta property="og:image:height" content={height} />
      <meta property="fb:app_id" content="395166778057652" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : null}
      {twitterImage ? (
        <meta name="twitter:image" content={twitterImage} />
      ) : null}
      <meta name="twitter:site" content="@amiinporn" />
      {languageRoutes.map(({ language, path }) => (
        <link
          rel="alternate"
          key={language}
          href={`${basePath}${path}`}
          hrefLang={language}
        />
      ))}
    </Helmet>
  )
}

export default Seo
