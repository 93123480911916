const STORAGE_KEY = 'order'
const ONE_DAY = 1000 * 60 * 60 * 24

export const getOrder = () => {
  const rawOrder = global.localStorage.getItem(STORAGE_KEY)
  const order = rawOrder ? JSON.parse(rawOrder) : null

  if (order && order.status === 'COMPLETED') {
    const isExpired = new Date() - new Date(order.update_time) > ONE_DAY

    if (isExpired) {
      global.localStorage.removeItem(STORAGE_KEY)
      return null
    } else {
      return order
    }
  } else {
    return null
  }
}

export const setOrder = order => {
  global.localStorage.setItem(STORAGE_KEY, JSON.stringify(order))
}
