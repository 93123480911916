/* globals localStorage, FormData */

import { getRoute } from "../../../lib/apiRoutes";
import { ImageSelector } from "../index";
import React, { Suspense, lazy, useState } from "react";

import { useNavigate } from "react-router-dom";
import dataURLtoBlob from "../../helpers/dataUrlToBlob";
import useImageUpload from "../../hooks/useImageUpload";
import { trackEvent } from "../googleAnalytics";

import { useCountryRestriction } from "../countryRestrictor";
import VisibilitySensor from "react-visibility-sensor";
import { getPath } from "../../routes";
import usePrice from "../../hooks/usePrice";
import { resizeImage } from "../../helpers/image";
import { trackFb } from "../../helpers/facebookTracking";
import fixOrientation from "../../../lib/fix-orientation";

const Payment = lazy(() => import("../payment"));
const CountryReleaseModal = lazy(() => import("../countryReleaseModal"));

const Checkout = () => {
  const { country, isAvailable } = useCountryRestriction();
  const navigate = useNavigate();
  const { currency_code, value: price } = usePrice();
  const storedImage = localStorage.getItem("selectedImage");
  const [showRestrictionModal, setModal] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);

  const [image, setImage] = useState(storedImage);
  const [selectorType, setSelectorType] = useState(
    localStorage.getItem("selectorType")
  );

  const trackImageSelectorImpression = (isVisible) => {
    if (isVisible) {
      trackEvent({
        category: "ImageSelector",
        action: "Impression",
        nonInteraction: true,
      });
    }
  };

  const uploadImage = useImageUpload({
    onComplete(xhr) {
      const mission = xhr.response;

      localStorage.removeItem("order");
      localStorage.removeItem("selectedImage");
      localStorage.removeItem("selectorType");

      trackFb("track", "Purchase", {
        value: price,
        currency: currency_code,
      });

      const path = getPath("missionsShow", { missionUuid: mission.uuid });
      navigate(path, { state: { mission } });
    },
    onError(xhr) {
      trackEvent({
        category: "Order",
        action: "Error",
      });

      setSubmiting(false);
      setSubmitError(true);
    },
    url: getRoute("orders"),
  });

  const onSubmit = (data) => {
    if (data.general_updates === "1") {
      trackEvent({
        category: "Order",
        action: "Check",
        label: "general_updates",
      });
      trackFb("track", "Lead");
    }
    trackEvent({
      category: "Order",
      action: "Submit",
    });

    global.AMIINPORN.orderCreatedAt = Date.now();

    setSubmiting(true);
    const formData = new FormData();
    for (const key in data) {
      formData.append(`order[${key}]`, data[key]);
    }

    const image = localStorage.getItem("selectedImage");
    dataURLtoBlob(image, (blob) => {
      formData.append("mission[image]", blob);
      uploadImage(formData);
    });
  };

  const onImageSelect = async (rawImage, { type }) => {
    trackEvent({
      category: "ImageSelector",
      action: "Select",
      label: type,
    });
    if (!isAvailable) {
      setModal(true);
      return;
    }

    if (rawImage) {
      fixOrientation(rawImage, async (rotatedImage) => {
        const image = await resizeImage(rotatedImage, 800, 800);
        localStorage.setItem("selectedImage", image);
        localStorage.setItem("selectorType", type);
        setSelectorType(type);
        setImage(image);
      });
    }
  };

  const onImageReset = (e) => {
    e.preventDefault();

    trackEvent({
      category: "Order",
      action: "Change",
      label: "image",
    });

    localStorage.removeItem("selectedImage");
    localStorage.removeItem("selectorType");
    setImage(null);
  };

  const stepComponent =
    !isAvailable || image === null ? (
      <VisibilitySensor delayedCall onChange={trackImageSelectorImpression}>
        <ImageSelector onImageSelect={onImageSelect} />
      </VisibilitySensor>
    ) : (
      <Suspense>
        <Payment
          image={image}
          imageSelectorType={selectorType}
          onImageReset={onImageReset}
          onSubmit={onSubmit}
          isSubmiting={isSubmiting}
          isSubmitError={isSubmitError}
        />
      </Suspense>
    );

  return (
    <>
      {!isAvailable && (
        <CountryReleaseModal
          restrictedCountry={country}
          show={showRestrictionModal}
          onClose={() => setModal(false)}
        />
      )}
      {stepComponent}
    </>
  );
};

export default Checkout;
