import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollIcon from "./scrollIcon";
import HeaderWhite from "../headerWhite";

import { Trans } from "react-i18next";

const Hero = () => {
  return (
    <div className="gradient py-5 mb-2">
      <Container>
        <HeaderWhite logo="lg" />
        <Row className="py-5 text-center justify-content-center">
          <Col lg={10}>
            <div className="hero-title">
              <Trans i18nKey="hero.headline">
                Image search engine for porn
                <br className="d-none d-sm-block" />
                to identify and remove personal content
              </Trans>
            </div>
            <p className="mt-5 mb-4 hero-subtitle">
              <Trans i18nKey="hero.subline">
                Every 5. woman is affected by revenge porn
                <br className="d-sm-none" />
                Our search engine will find you in millions of videos by image
                matching.
              </Trans>
            </p>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <ScrollIcon />
        </div>
      </Container>
    </div>
  );
};

export default Hero;
