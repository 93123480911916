import React from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../routes";

const ButtonLink = ({ to, children, onClick, ...props }) => {
  const navigate = useNavigate();
  const _onClick = (e) => {
    onClick && onClick(e);
    if (!props.target) {
      e.preventDefault();
      navigate(getPath(to));
    }
  };

  return (
    <Button onClick={_onClick} {...props}>
      {children}
    </Button>
  );
};

export default ButtonLink;
