import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { FullscreenSpinner } from "../components";
import { routeHandler } from "../routes";
import NotFound from "./errors/notFound";

const Screens = () => {
  const routeElements = routeHandler.getRoutes().map(({ key, ...props }) => {
    return (
      <Route
        key={props.language + " " + key}
        element={<props.Component routeKey={key} />}
        {...props}
      />
    );
  });
  return (
    <Suspense fallback={<FullscreenSpinner />}>
      <Routes>
        {[
          ...routeElements,
          <Route key="404" path="/404" element={<NotFound />} />,
        ]}
      </Routes>
    </Suspense>
  );
};

export default Screens;
