import React, { useRef, useState } from 'react'
import { animated, useTrail, useSpring } from 'react-spring'

const ScrollIcon = () => {
  const animationCounter = useRef(0)
  const [fadeIn, set] = useState(true)
  const [showArrows, setShowArrows] = useState(false)

  const onRest = () => {
    if (showArrows) {
      animationCounter.current++
    }

    if (animationCounter.current === 3) {
      animationCounter.current = 1
      setTimeout(() => {
        set(!fadeIn)
      }, 500)
    }
  }

  const container = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,-20px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    delay: 1000,
    onRest: () => setShowArrows(true)
  })

  const from = { opacity: 0, transform: 'translate3d(0,-20px,0)' }
  const to = fadeIn
    ? {
        opacity: 1,
        transform: 'translate3d(0,0,0)'
      }
    : {
        opacity: 0,
        transform: 'translate3d(0,20px,0)'
      }
  const arrows = useTrail(3, {
    from,
    reset: fadeIn,
    to: showArrows ? to : from,
    onRest
  })

  return (
    <animated.svg
      style={container}
      width="32"
      height="48"
      xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="rgba(255,255,255,0.15">
        <rect
          stroke="rgba(255,255,255,0.5"
          strokeWidth="2"
          x="1"
          y="1"
          width="30"
          height="46"
          rx="12"
        />
        <animated.path
          style={arrows[0]}
          fill="#fff"
          d="M16.015 38L10 31.797 11.947 30l4.063 4.19 4.038-4.187L22 31.794z"
        />
        <animated.path
          style={arrows[1]}
          fill="#fff"
          d="M16.008 27.333l-4.01-4.135L13.296 22l2.709 2.793 2.692-2.791 1.301 1.194z"
        />
        <animated.path
          style={arrows[2]}
          fill="#fff"
          d="M16.003 18.667l-2.005-2.068.65-.599L16 17.397 17.348 16l.65.597z"
        />
      </g>
    </animated.svg>
  )
}

export default ScrollIcon
