import React, { useLayoutEffect, useState } from 'react'
import useRect from '../../hooks/useRect'
import { animated, useSpring } from 'react-spring'
import { trackEvent } from '../googleAnalytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AnimatedIcon = animated(FontAwesomeIcon)

const Entry = ({ id, title, text, initialOpen = false }) => {
  const [isOpen, setOpen] = useState(initialOpen)
  const [height, setHeight] = useState(null)
  const [bind, { height: rectHeight }] = useRect()
  useLayoutEffect(() => {
    if (rectHeight > 0) {
      setHeight(rectHeight)
    }
  }, [rectHeight])
  const props = useSpring({
    height: height ? (isOpen ? height : 0) : 'auto',
    config: { mass: 1, tension: 190, friction: 20, clamp: true }
  })
  const { r } = useSpring({
    r: !isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
    config: { mass: 1, tension: 170, friction: 18, clamp: false }
  })

  const onClick = () => {
    if (!isOpen) {
      trackEvent({
        category: 'FAQ',
        action: 'Click',
        label: id
      })
    }
    setOpen(!isOpen)
  }

  return (
    <div className="faq-entry mb-3 border rounded shadow-sm">
      <h5 className="m-0 d-flex align-items-center p-3" onClick={onClick}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <AnimatedIcon
          className="ml-auto"
          style={{
            transform: r
          }}
          icon="arrow-right"
        />
      </h5>
      <animated.div style={{ overflow: 'hidden', ...props }}>
        <div
          {...bind}
          className="p-3 pt-2"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </animated.div>
    </div>
  )
}

export default Entry
