import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import "./app/styles/index.scss";
import * as Sentry from "@sentry/browser";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn:
    "https://5b69853369d34abaab3f1d32999e9fb2@o244189.ingest.sentry.io/5294342",
});

global.AMIINPORN = global.AMIINPORN || {};
const SHOULD_HYDRATE = false;

const rootElement = document.getElementById("root");
if (SHOULD_HYDRATE && rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  const root = createRoot(rootElement);
  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
