import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageBox from "./imageBox";

import PricingTable from "./pricingTable";
import OrderConfirmation from "./orderConfirmation/index";
import { useTranslation } from "react-i18next";
import usePrice from "../../hooks/usePrice";

import { getOrder, setOrder } from "../../helpers/order";
import { trackFb } from "../../helpers/facebookTracking";
import useHelpline from "../../hooks/useHelpline";

const Payment = ({
  image,
  imageSelectorType,
  onImageReset,
  isSubmiting,
  isSubmitError,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const { currency_code, value: price } = usePrice();
  const [order, _setOrder] = useState(getOrder());
  const { isHelpline } = useHelpline();
  const onAuthorize = (order) => {
    _setOrder(order);
    setOrder(order);
  };

  useEffect(() => {
    trackFb("track", "InitiateCheckout", {
      value: price,
      currency: currency_code,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Row>
      <Col md={5} className="mb-4 mb-md-0 text-center text-md-left">
        <ImageBox image={image} />
        <Button
          variant="link"
          size="md"
          className="px-0"
          onClick={onImageReset}
        >
          <FontAwesomeIcon className="mr-1" icon="image"></FontAwesomeIcon>
          <span className="btn__text">{t("payment.changeImage")}</span>
        </Button>
      </Col>
      <Col>
        {isHelpline ? (
          <div>
            <Button onClick={() => onSubmit({})}>Start search</Button>
          </div>
        ) : order ? (
          <OrderConfirmation
            image={image}
            order={order}
            onSubmit={onSubmit}
            isSubmiting={isSubmiting}
            isSubmitError={isSubmitError}
          />
        ) : (
          <PricingTable onAuthorize={onAuthorize} />
        )}
      </Col>
    </Row>
  );
};

export default Payment;
