import React from "react";
import Gear from "../gear";
import { useSpring, animated } from "react-spring";
import { Trans, useTranslation } from "react-i18next";

const fadeIn = {
  opacity: 1,
  transform: "translate3d(0,0,0)",
};
const start = {
  opacity: 0,
  transform: "translate3d(0,50px,0)",
};

const Progress = () => {
  const style = useSpring({ from: start, to: fadeIn, delay: 100 });
  const style2 = useSpring({ from: start, to: fadeIn, delay: 300 });
  const [t] = useTranslation();

  return (
    <div className="text-center fullscreen-overlay" style={{ zIndex: 300 }}>
      <div
        className="min-h-100 d-flex flex-column align-items-center justify-content-center p-5"
        style={style}
      >
        <div className="alert alert-warning mb-5" role="alert">
          {t("progress.info")}
        </div>
        <animated.div style={style}>
          <div className="mb-5">
            <Gear />
          </div>
        </animated.div>
        <animated.div style={style2}>
          <div className="progress-head">
            <div className="h4 mb-3">{t("progress.headline")}</div>
            <p className="lead mb-5">
              <Trans i18nKey="progress.lead">
                Millions of videos will now be compared...
                <br /> But it takes just seconds until you have your results!
              </Trans>
            </p>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default Progress;
