import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HelpOver = ({ id, content, children, isSuper = false }) => (
  <span className={isSuper ? 't-super' : ''}>
    <span className="t-size-0">
      <OverlayTrigger
        trigger="hover"
        placement={'bottom'}
        overlay={
          <Popover id={id}>
            <Popover.Content>{content}</Popover.Content>
          </Popover>
        }>
        {<span>{children}</span> || (
          <span className="p-1">
            <FontAwesomeIcon
              fixedWidth
              icon="question-circle"></FontAwesomeIcon>
          </span>
        )}
      </OverlayTrigger>
    </span>
  </span>
)

export default HelpOver
