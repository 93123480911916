import React from 'react'
import useBreakpoint from '../../hooks/useBreakpoint'
import { trackEvent } from '../googleAnalytics'
import Desktop from './desktop'
import Mobile from './mobile'

const links = [
 {
    to: 'openPositions',
    i18nKey: 'navigation.openpositions'
  }, 
  {
    to: 'faq',
    i18nKey: 'navigation.faq'
  },
  {
    to: 'insights',
    i18nKey: 'navigation.insights'
  },
  {
    to: 'ourMission',
    i18nKey: 'navigation.mission'
  },
]

const Header = props => {
  const { down } = useBreakpoint()
  const Component = down('sm') ? Mobile : Desktop
  const trackClick = label => () => {
    trackEvent({
      category: 'Content',
      action: 'Click',
      label
    })
  }

  return <Component trackClick={trackClick} links={links} {...props} />
}

export default Header
