const apiHost =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://api.amiinporn.org"
    : "http://api.lvh.me:3000";

export const apiRoutes = {
  countries: "/countries",
  contactMessages: "/contact_messages",
  newsletterRecipients: "/newsletter_recipients",
  missions: "/missions",
  mission: "/missions/:uuid",
  missionsStart: "/missions/start",
  orders: "/orders",
  ordersRetry: "/orders/retry",
};

export const getRoute = (name, params = {}) =>
  Object.keys(params).reduce(
    (acc, current) => acc.replace(`:${current}`, params[current]),
    apiHost + apiRoutes[name]
  );
