import { compose, equals, filter, isNil, path, prop, sortBy, when } from 'ramda'

import Col from 'react-bootstrap/Col'
import React, { useEffect } from 'react'
import ResultItem from '../resultItem'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import Head from './head'
import { trackEvent } from '../googleAnalytics'

const ResultListing = ({
  items,
  email,
  isReported,
  onSetOccurrence,
  occurrencesCount,
  onReport
}) => {
  let listing
  const [t] = useTranslation()
  const buildItems = items => {
    return items.map((item, index) => (
      <ResultItem
        {...item}
        isReported={isReported}
        index={index + 1}
        key={item.id}
        onSetOccurrence={onSetOccurrence}
      />
    ))
  }

  const reportButton = (
    <Button onClick={onReport} className="btn-icon">
      <FontAwesomeIcon icon={['far', 'flag']} />{' '}
      {t('resultListing.report', { count: occurrencesCount })}
    </Button>
  )
  const noReportButton = (
    <Button onClick={onReport} className="btn-icon">
      <FontAwesomeIcon icon={['far', 'smile']} />{' '}
      {t('resultListing.reportNone')}
    </Button>
  )

  const videosLength = items.length
  const highestPercentage = path(['data', 'similarityPercentage'], items[0])

  useEffect(() => {
    if (!isReported) {
      trackEvent({
        category: 'Results',
        action: 'Impression',
        nonInteractive: true
      })
      trackEvent({
        category: 'Results',
        action: 'Best Similarity',
        value: highestPercentage,
        nonInteractive: true
      })
    }
    // eslint-disable-next-line
  }, [])

  if (isReported) {
    const filterByOccurrence = (isOccurrence, items) => {
      const equalsOccurrence = compose(
        equals(isOccurrence),
        when(isNil, () => false),
        prop('is_occurrence')
      )

      return compose(
        sortBy(path(['data', 'similarity'])),
        filter(equalsOccurrence)
      )(items)
    }

    const occurrenceItems = filterByOccurrence(true, items)
    const noOccurrenceItems = filterByOccurrence(false, items)

    listing = (
      <>
        <Container className="mt-4">
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={5}>
              <h4 className="text-center mt-4 mb-3">
                {t('resultListing.occurrencesHeadline', {
                  count: occurrenceItems.length
                })}
              </h4>
              <Row>{buildItems(occurrenceItems)}</Row>
            </Col>
          </Row>
          {occurrencesCount > 0 && (
            <Row className="mb-5">
              <Col className="text-center">{reportButton}</Col>
            </Row>
          )}
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={5}>
              <h4 className="text-center mt-4 mb-3">
                {t('resultListing.noOccurrencesHeadline', {
                  count: noOccurrenceItems.length
                })}
              </h4>
              <Row>{buildItems(noOccurrenceItems)}</Row>
            </Col>
          </Row>
        </Container>
      </>
    )
  } else {
    listing = (
      <>
        <Head
          email={email}
          videosLength={videosLength}
          highestPercentage={highestPercentage}
        />
        <Container className="mt-4" data={{ testId: 'result-listing' }}>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={5}>
              <Row>{buildItems(items)}</Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              {occurrencesCount > 0 ? reportButton : noReportButton}
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  return listing
}

export default ResultListing
