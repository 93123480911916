import React from 'react'
import Container from 'react-bootstrap/Container'
import Link from '../link'
import { trackEvent } from '../googleAnalytics'

import logoSrc from '../../images/logo-white.svg'

const Header = ({ logo = 'md' }) => {
  const trackClick = () => {
    trackEvent({
      category: 'Content',
      action: 'Click',
      label: 'logo'
    })
  }
  return (
    <header className="mb-5 text-center">
      <Container>
        <Link to="start" onClick={trackClick}>
          <img className={`logo-white ${logo}`} alt="Logo" src={logoSrc} />
        </Link>
      </Container>
    </header>
  )
}

export default Header
