import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation, Trans } from "react-i18next";
import Link from "../../link";
import usePrice from "../../../hooks/usePrice";

const Description = () => {
  const [t] = useTranslation();
  const { formattedValue } = usePrice();

  return (
    <Row className="mb-5 mb-md-4">
      <Col>
        <div className="font-weight-bold mb-2">{t("description.headline")}</div>
        <Row>
          <Col>
            <Trans i18nKey="description.text">
              Image based search query and provision of search results (
              <Link target="_blank" to="serviceDescription">
                See service description
              </Link>
              )
            </Trans>
          </Col>
          <Col className="text-right">{formattedValue}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Description;
