/* globals FormData */
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

import { ImageSelector } from "../";
import { getRoute } from "../../../lib/apiRoutes";
import useImageUpload from "../../hooks/useImageUpload";
import dataURLtoBlob from "../../helpers/dataUrlToBlob";
import { getPath } from "../../routes";
import { trackEvent } from "../googleAnalytics";
import { Trans, useTranslation } from "react-i18next";

const FaceNotFound = ({ mission }) => {
  const typeRef = useRef();
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent({
      category: "Results",
      action: "No Face Detected",
    });
  }, []);

  const uploadImage = useImageUpload({
    onStart() {
      setLoading(true);
      setError(false);
    },
    onComplete(xhr) {
      trackEvent({
        category: "Results",
        action: "Reupload Image",
        label: typeRef.current,
      });

      const mission = xhr.response;
      const path = getPath("missionsShow", { missionUuid: mission.uuid });
      navigate(path);
    },
    onError(xhr) {
      // handle Upload error
      setLoading(false);
      setError(true);
    },
    url: getRoute("ordersRetry"),
  });

  const onImageSelect = (image, { type }) => {
    typeRef.current = type;

    const data = new FormData();
    data.append("order[id]", mission.order_id);

    dataURLtoBlob(image, (blob) => {
      data.append("mission[image]", blob);
      uploadImage(data);
    });
  };

  return (
    <Container className="text-center">
      <div>
        <img
          className="img-thumbnail rounded d-inline-block mb-4"
          src={mission.image_150x}
          alt=""
        />
      </div>
      <h3>{t("faceNotFound.headline")}</h3>
      <p className="lead">
        <Trans i18nKey="faceNotFound.noCharge">
          Of course we did <strong className="font-weight-bold">not</strong>{" "}
          charge you for that.
        </Trans>
      </p>
      {isError ? (
        <p className="text-danger">{t("faceNotFound.errorMessage")}</p>
      ) : (
        <p>{t("faceNotFound.tryWithDifferentImage")}</p>
      )}
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <ImageSelector onImageSelect={onImageSelect} />
      )}
    </Container>
  );
};

export default FaceNotFound;
