import React, { useEffect, useState } from "react";
import Link from "../link";

import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";
import { trackEvent } from "../googleAnalytics";
import { Trans, useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Form, Row } from "react-bootstrap";

const isReactSnap = navigator.userAgent === "ReactSnap";
const SHOULD_HIDE_BANNER = isReactSnap || Cookies.get("hide-cookies-banner");

const CookiesBanner = () => {
  const [hideBanner, setHideBanner] = useState(true);
  const [checkedAnalytical, setAnalytical] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setHideBanner(SHOULD_HIDE_BANNER);
    if (!SHOULD_HIDE_BANNER) {
      trackEvent({
        category: "CookieBanner",
        action: "Impression",
        nonInteraction: true,
      });
    }
  }, []);

  if (hideBanner) return null;
  const onAccept = (type) => () => {
    const analytical = type === "all" || checkedAnalytical;

    trackEvent({
      category: "CookieBanner",
      action: "Click",
      label: type,
      value: analytical ? 1 : 0,
    });

    setHideBanner(true);
    Cookies.set("analytical-cookies", analytical ? 1 : 0, { expires: 365 });
    Cookies.set("hide-cookies-banner", 1, { expires: 365 });
  };

  const trackPrivacy = () => {
    trackEvent({
      category: "CookieBanner",
      action: "Click",
      label: "privacy_link",
    });
  };

  return (
    <Modal
      show={true}
      style={{ display: "block" }}
      backdrop="static"
      centered
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{t("cookiesBanner.title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t("cookiesBanner.description")}</p>
        <Link
          onClick={trackPrivacy}
          target="_blank"
          path="/legal/privacyStatement_en_191118.pdf"
        >
          {t("cookiesBanner.privacyTerms")}
        </Link>
        <hr />
        <Form>
          <Form.Check
            type="checkbox"
            checked
            disabled
            label={<Trans i18nKey="cookiesBanner.necessary" />}
          />
          <Form.Check
            type="checkbox"
            id="analytical"
            checked={checkedAnalytical}
            onChange={(e) => setAnalytical(!checkedAnalytical)}
            label={<Trans i18nKey="cookiesBanner.analytical" />}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button onClick={onAccept("selected")} block variant="secondary">
                {t("cookiesBanner.buttonSelected")}
              </Button>
            </Col>
            <Col>
              <Button onClick={onAccept("all")} block variant="primary">
                {t("cookiesBanner.buttonAll")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default CookiesBanner;
