export const resizeImage = (image64, maxWidth, maxHeight) => {
  return new Promise(resolve => {
    const img = document.createElement('img')
    img.onload = function() {
      let ratio
      if (this.width <= maxWidth && this.height <= maxHeight) {
        resolve(image64)
        return
      } else {
        ratio = this.naturalWidth / this.naturalHeight
      }

      let finalWidth, finalHeight
      if (ratio > 1) {
        finalWidth = maxWidth
        finalHeight = finalWidth / ratio
      } else {
        finalHeight = maxHeight
        finalWidth = finalHeight * ratio
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = finalWidth
      canvas.height = finalHeight

      ctx.drawImage(this, 0, 0, finalWidth, finalHeight)
      resolve(canvas.toDataURL())
    }

    img.src = image64
  })
}
