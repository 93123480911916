import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { fromEvent } from "file-selector";
import { trackEvent } from "../googleAnalytics";
import CameraModal from "./cameraModal";
import { useTranslation } from "react-i18next";

import camera from "./camera.svg";
import useBreakpoint from "../../hooks/useBreakpoint";

const options = {
  accept: "image/*",
  multiple: false,
};

const ImageSelector = ({ onImageSelect }) => {
  const selectorType = useRef();
  const mobileInputRef = useRef();
  const [t] = useTranslation();
  const { down } = useBreakpoint();

  const [showModal, setShowModal] = useState(false);
  const onCloseModal = () => setShowModal(false);

  const onTakePictureMobile = (e) => {
    e.stopPropagation();

    mobileInputRef.current.click();
  };

  const onTakePicture = (e) => {
    e.stopPropagation();

    trackEvent({
      category: "ImageSelector",
      action: "Open Camera",
    });

    setShowModal(true);
  };
  const webcamModal = (
    <CameraModal
      show={showModal}
      onImageSelect={onImageSelect}
      onClose={onCloseModal}
    />
  );

  // const onFileSelect = (e) => {
  //   mobileInputRef.current.click();
  // };

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    reader.onload = () => {
      onImageSelect(reader.result, { type: selectorType.current });
    };

    reader.readAsDataURL(file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    getFilesFromEvent: (e) => {
      if (e.dataTransfer && e.dataTransfer.files.length) {
        selectorType.current = "dragndrop";
      }
      if (!e.dataTransfer) {
        selectorType.current = "filedialog";
      }
      return fromEvent(e);
    },
    ...options,
  });
  const uploadForm = (
    <div
      {...getRootProps({
        className:
          "text-center shadow d-flex flex-column justify-content-center align-items-center",
      })}
    >
      <Row className="w-100 py-4 px-lg-5 justify-content-center align-items-stretch">
        <Col xs={12}>
          <div className="py-4 d-flex flex-sm-column justify-content-center align-items-center">
            <img className="image-icon" src={camera} alt="" />
            <div className="mt-3 ml-5 ml-sm-0">
              <input
                {...getInputProps()}
                ref={mobileInputRef}
                capture="user"
                hidden
              />
              <div>
                <button
                  onClick={down("sm") ? onTakePictureMobile : onTakePicture}
                  type="button"
                  className="btn btn-primary mb-3"
                >
                  {t("imageSelector.takePicture")}
                </button>
              </div>
              {/* <button
                onClick={onFileSelect}
                type="button"
                className="btn btn-primary mb-3"
              >
                {t("imageSelector.addImage")}
              </button> */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="image-drop-zone border-bottom">
      {uploadForm}
      {webcamModal}
    </div>
  );
};

export default ImageSelector;
