import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { trackEvent } from "../googleAnalytics";

import amazon from "../../images/social-proof/aws.svg";
import { useTranslation } from "react-i18next";

const logo = {
  url: "https://aws.amazon.com/activate/",
  label: "amazon",
  src: amazon,
};

const AwsActivateProgram = () => {
  const [t] = useTranslation();

  const onClick = (label) => () => {
    trackEvent({
      category: "SocialProof",
      action: "Click",
      label,
    });
  };

  return (
    <Container className="pt-2 d-flex flex-column align-items-center mb-5">
      <p className="h5">
        <strong>{t("awsActivateProgram.knownFrom")}</strong>
      </p>
      <Row className="align-items-center justify-content-center">
        <div>
          <a
            className="my-2 p-3 p-sm-2 p-lg-2 d-block"
            onClick={logo.url && onClick(logo.label)}
            target="_blank"
            rel="noopener noreferrer"
            href={logo.url}
          >
            <img
              className="social-proof-logo"
              alt={`${logo.label} logo`}
              src={logo.src}
            />
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default AwsActivateProgram;
