import { animated, useSpring } from "react-spring";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import { trackEvent } from "../googleAnalytics";
import { useTranslation } from "react-i18next";

const AnimatedButton = animated(Button);

const withZeroPrepended = (number) =>
  String(number > 9 ? number : `0${number}`);

const secondsToTime = (secondsTotal) => {
  const seconds = withZeroPrepended(secondsTotal % 60);
  const minutes = Math.floor(secondsTotal / 60);

  return `${minutes}:${seconds}`;
};

const timeToSeconds = (str) => {
  const time = str.split(":");
  const seconds = parseInt(time[time.length - 1]);
  const minutes = parseInt(time[time.length - 2]);

  return minutes * 60 + seconds;
};

const ResultItem = ({
  index,
  id,
  data,
  is_occurrence: isOccurrence,
  onSetOccurrence,
  isReported,
}) => {
  const {
    duration,
    video_id: videoId,
    title,
    similarityPercentage: similarity,
    seeking_position: seekingPositionInSeconds,
  } = data;
  const [t] = useTranslation();
  const [isInstaOccurrence, setInstaOccurrence] = useState(isOccurrence);

  const durationInSeconds = timeToSeconds(duration);

  const seekingTime = secondsToTime(seekingPositionInSeconds);
  const seekingPosition = (seekingPositionInSeconds / durationInSeconds) * 100;

  const handleOccurence = (isOccurrence) => {
    setInstaOccurrence(isOccurrence);
    onSetOccurrence({ id, isOccurrence });
  };

  // Animation
  const props = useSpring({
    borderColor:
      isOccurrence === true ? "rgba(200,200,200,1)" : "rgba(200,200,200,0)",
    opacity: !isReported && isOccurrence === false ? 0.4 : 1,
  });
  const buttonColor = useSpring({
    immediate: true,
    no:
      isInstaOccurrence === null || isInstaOccurrence === true
        ? "#FF5943"
        : "#fff",
    yes:
      isInstaOccurrence === null || isInstaOccurrence === false
        ? "#FF5943"
        : "#fff",
  });
  const buttonBg = useSpring({
    immediate: true,
    no:
      isInstaOccurrence === null || isInstaOccurrence === true
        ? "#fff"
        : "#FF5943",
    yes:
      isInstaOccurrence === null || isInstaOccurrence === false
        ? "#fff"
        : "#FF5943",
  });

  const searchUrl = `https://duckduckgo.com/?q=pornhub+${videoId}+${encodeURI(
    title
  )}`;

  const onClick = () => {
    trackEvent({
      category: "Results",
      action: "Click Go To Video",
      label: searchUrl,
      value: index,
    });
  };

  return (
    <Col xs={12} className="mb-5">
      <animated.div className="shadow result-item p-4 rounded" style={props}>
        <Row>
          <Col className="justify-content-center d-flex flex-column mw-0 align-items-start">
            <a
              onClick={onClick}
              href={searchUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="title"
            >
              <span>
                {index}. {title}
              </span>
              <div className="external-link">
                <FontAwesomeIcon icon="external-link-alt" size="xs" />
              </div>
            </a>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={5}>
            <div className="probability rounded">
              {similarity}
              <small>%</small>
            </div>
            <div className="probability-text">
              {t("resultItem.probability")}
            </div>
          </Col>
          <Col className="justify-content-center d-flex flex-column">
            <div className="seeking-bar">
              <span
                className="seeking-position"
                style={{ left: `${seekingPosition}%` }}
              />
            </div>
            <div className="seeking-time mt-2">
              <FontAwesomeIcon icon="play" size="xs" className="mr-2" />{" "}
              {seekingTime} / {duration}
            </div>
          </Col>
        </Row>
        {!isReported && (
          <Row className="mt-5">
            <Col
              xs={5}
              className="align-items-center d-flex reporting font-weight-bold"
            >
              {t("resultItem.isItYou")}
            </Col>
            <Col>
              <Row>
                <Col>
                  <ButtonGroup className="reporting-buttons w-100">
                    <AnimatedButton
                      variant="outline-primary"
                      style={{ background: buttonBg.no, color: buttonColor.no }}
                      onClick={() => handleOccurence(false)}
                    >
                      {t("resultItem.no")}
                    </AnimatedButton>
                    <AnimatedButton
                      variant="outline-primary"
                      style={{
                        background: buttonBg.yes,
                        color: buttonColor.yes,
                      }}
                      onClick={() => handleOccurence(true)}
                    >
                      {t("resultItem.yes")}
                    </AnimatedButton>
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </animated.div>
    </Col>
  );
};

export default ResultItem;
