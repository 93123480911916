/* globals XMLHttpRequest */

function dataURLtoBlob(dataUrl, callback) {
  var req = new XMLHttpRequest()

  req.open('GET', dataUrl)
  req.responseType = 'blob'

  req.onload = function fileLoaded(e) {
    callback(this.response)
  }

  req.send()
}

export default dataURLtoBlob
