import React, { useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Link from '../../link'
import { Trans, useTranslation } from 'react-i18next'

const Legal = ({ validated, onChange }) => {
  const tosRef = useRef()
  const cancellationPeriodRef = useRef()
  const newsletterRef = useRef()
  const [t] = useTranslation()

  const _onChange = () => {
    onChange({
      termsOfService: tosRef.current.checked,
      cancellationPeriod: cancellationPeriodRef.current.checked,
      newsletterConsent: newsletterRef.current.checked
    })
  }

  return (
    <Form noValidate validated={validated}>
      <Row>
        <Col>
          <div>
            <Form.Group controlId="tos">
              <Form.Check
                ref={tosRef}
                required
                type="checkbox"
                onChange={_onChange}
                label={
                  <small>
                    (*){' '}
                    <Trans i18nKey="legal.acceptingTerms">
                      I accept the{' '}
                      <Link
                        to="terms"
                        target="_blank"
                        rel="noopener noreferrer">
                        terms and conditions
                      </Link>{' '}
                      and waive my right of withdrawal
                    </Trans>
                  </small>
                }
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="cancellationPeriod">
              <Form.Check
                ref={cancellationPeriodRef}
                required
                type="checkbox"
                onChange={_onChange}
                label={
                  <small>(*) {t('legal.acceptingCancellationPeriod')}</small>
                }
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="newsletter">
              <Form.Check
                ref={newsletterRef}
                type="checkbox"
                feedback={null}
                onChange={_onChange}
                label={
                  <small>
                    <Trans i18nKey="legal.generalUpdates">
                      Please send me latest news about new products and features
                      of Am I In Porn? regularly. I agree that my data is used
                      by mail service providers like Mailchimp for analysis. I
                      can unsubscribe at any time. View{' '}
                      <Link
                        to="privacy"
                        target="_blank"
                        rel="noopener noreferrer">
                        privacy policy
                      </Link>
                      .
                    </Trans>
                  </small>
                }
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default Legal
