import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { join, compose, reject, isNil } from 'ramda'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const formatAdminArea = address =>
  compose(
    join(', '),
    reject(isNil)
  )([
    address.admin_area_4,
    address.admin_area_3,
    address.admin_area_2,
    address.admin_area_1
  ])

const Address = ({ onEdit, address = {}, customer }) => {
  const [t] = useTranslation()
  return (
    <>
      <Row>
        {/* <Col xs={12} className="font-weight-bold ">
          <div className="h4">{t('address.checkFromPaypal')}</div>
          <hr className="mt-1 mb-3" />
        </Col> */}

        <Col xs={12} md={6}>
          <div className="font-weight-bold mb-2">
            {t('address.billingAddress')}
          </div>
          <div>{`${customer.first_name} ${customer.last_name}`}</div>
          <div>{address.address_line_1}</div>
          {address.address_line_2 && <div>{address.address_line_2}</div>}
          {address.address_line_3 && <div>{address.address_line_3}</div>}
          <div>
            {address.postal_code} {formatAdminArea(address)},{' '}
            {address.country_code}
          </div>
        </Col>
        <Col className="mt-3 mt-md-0 text-md-right">
          <div className="font-weight-bold mb-2">{t('address.email')}</div>
          <div>{customer.email_address}</div>
        </Col>
      </Row>
      <Row className="mb-5 mb-md-4">
        <Col>
          <Button variant="link" size="md" className="px-0" onClick={onEdit}>
            <FontAwesomeIcon className="mr-1" icon="edit"></FontAwesomeIcon>
            <span className="btn__text">{t('address.btnChange')}</span>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default Address
