import React, { Suspense, useState, useEffect } from "react";
import { map, prop } from "ramda";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./i18n";
import {
  CookiesBanner,
  DonateBanner,
  Header,
  Footer,
  ScrollHandler,
  CountryRestrictor,
  FullscreenSpinner,
} from "./components";
import Screens from "./screens";

import { getAllRoutesWhere, buildRoutes, routeHandler } from "./routes";
import "./icons";
import useLanguageSelection from "./hooks/useLanguageSelection";
import { useTranslation } from "react-i18next";
import routeTypes from "./routeTypes";
import "./helpers/textEncoder";
import { init as initGA, RouteTracker } from "./components/googleAnalytics";

import { Pricing } from "./hooks/usePrice";

const RoutedApp = () => (
  <Suspense fallback={<FullscreenSpinner />}>
    <Router>
      <CountryRestrictor>
        <App />
      </CountryRestrictor>
    </Router>
  </Suspense>
);

const isGAEnabled = initGA();

const App = () => {
  const [customHeaderPaths, setCustomHeaderPaths] = useState([]);
  useEffect(() => {
    setCustomHeaderPaths(
      map(prop("path"), getAllRoutesWhere({ customHeader: true }))
    );
  }, []);

  useLanguageSelection();

  const { i18n } = useTranslation();
  const routes = buildRoutes(i18n, routeTypes);
  routeHandler.init({ routes, language: i18n.language });

  return (
    <ScrollHandler>
      <Pricing>
        <DonateBanner />
        <CookiesBanner />
        {isGAEnabled && <RouteTracker />}
        <Routes>
          {customHeaderPaths.map((path) => (
            <Route path={path} key={path} element={null} />
          ))}
          <Route path="*" element={<Header />} />
        </Routes>
        <div className="main-container">
          <Screens />
          <Footer />
        </div>
      </Pricing>
    </ScrollHandler>
  );
};

export default RoutedApp;
