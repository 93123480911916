import React from 'react'
import { animated, useSpring, useTrail } from 'react-spring'

const from = {
  mid: {
    opacity: 1,
    x: 0
  },
  cross: {
    axyUpper: [0, 0, 0],
    axyLower: [0, 0, 0]
  }
}
const to = {
  mid: {
    opacity: 0,
    x: 20
  },
  cross: {
    axyUpper: [45, 0, 14],
    axyLower: [-45, 0, -12]
  }
}

const MenuButton = ({ onClick, isOpen }) => {
  const cross = useSpring({
    from: from.cross,
    to: isOpen ? to.cross : from.cross
  })

  const mid = useTrail(2, {
    from: from.mid,
    to: isOpen ? to.mid : from.mid
  })

  return (
    <div onClick={onClick} className="mobile-menu-button">
      <svg
        width="60"
        height="60"
        overflow="auto"
        xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero" fill="none">
          <animated.path
            style={{
              transformOrigin: '30px 17px',
              transform: cross.axyUpper.interpolate(
                (a, x, y) => `translate3d(${x}px, ${y}px, 0) rotate(${a}deg)`
              )
            }}
            d="M43.949 19.269H16.05c-1.133 0-2.051-.952-2.051-2.126 0-1.175.918-2.126 2.051-2.126H43.95c1.133 0 2.051.951 2.051 2.126 0 1.174-.918 2.126-2.051 2.126z"
            fill="#FF5943"
          />
          <animated.path
            style={{
              ...mid[0],
              transform: mid[0].x.interpolate(x => `translate3d(${x}px,0,0)`)
            }}
            d="M32.462 32.025h-16.41c-1.134 0-2.052-.951-2.052-2.126 0-1.174.918-2.126 2.051-2.126h16.41c1.134 0 2.052.952 2.052 2.126 0 1.175-.918 2.126-2.051 2.126z"
            fill="#FF5943"
          />
          <animated.path
            style={{
              ...mid[1],
              transform: mid[1].x.interpolate(x => `translate3d(${x}px,0,0)`)
            }}
            d="M43.963 32.037h-3.26c-1.125 0-2.036-1.068-2.036-2.385 0-1.318.911-2.385 2.037-2.385h3.259c1.125 0 2.037 1.067 2.037 2.385 0 1.317-.912 2.385-2.037 2.385z"
            fill="#FFC7C0"
          />
          <animated.path
            style={{
              transformOrigin: '30px 43px',
              transform: cross.axyLower.interpolate(
                (a, x, y) => `translate3d(${x}px, ${y}px, 0) rotate(${a}deg)`
              )
            }}
            d="M43.949 44.985H16.05C14.918 44.985 14 43.918 14 42.6c0-1.318.918-2.385 2.051-2.385H43.95c1.133 0 2.051 1.067 2.051 2.385 0 1.318-.918 2.385-2.051 2.385z"
            fill="#FF5943"
          />
        </g>
      </svg>
    </div>
  )
}

export default MenuButton
