import React, { useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'

const useRotate = duration => {
  const req = useRef()
  const run = useRef(true)
  useEffect(() => () => (run.current = false), [])

  const { rotate } = useSpring({
    config: {
      duration: duration
    },
    from: {
      rotate: '0deg'
    },
    reset: true,
    to: async next => {
      const frame = async () => {
        await next({
          rotate: '360deg'
        })
        if (run.current) {
          req.current = window.requestAnimationFrame(frame)
        } else {
          window.cancelAnimationFrame(req.current)
        }
      }

      req.current = window.requestAnimationFrame(frame)
    }
  })

  return { rotate }
}

const Gear = () => {
  const { rotate } = useRotate(3000)
  const { rotate: rotate2 } = useRotate(1600)
  const { rotate: rotate3 } = useRotate(1800)

  return (
    <div className="gear">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 472 472">
        <g fill="none">
          <path
            d="M88 272c0 22.09-17.91 40-40 40S8 294.09 8 272s17.91-40 40-40 40 17.91 40 40zM200 312c0 22.09-17.91 40-40 40s-40-17.91-40-40 17.91-40 40-40 40 17.91 40 40z"
            fill="#FFCD34"
          />
          <path
            d="M112 424c0 22.09-17.91 40-40 40s-40-17.91-40-40 17.91-40 40-40 40 17.91 40 40z"
            fill="#FF5943"
          />
          <g fill="#D53E5F">
            <path d="M56 272a8 8 0 11-16 0 8 8 0 0116 0zM168 312a8 8 0 11-16 0 8 8 0 0116 0zM80 424a8 8 0 11-16 0 8 8 0 0116 0z" />
          </g>
          <path
            d="M71.953 472h-.457C32 469.48 23.2 439.2 24 423.566a48.492 48.492 0 0127.688-43.062l118.105-38.031a35.116 35.116 0 0020.102-18.946 37.211 37.211 0 00-1.239-25.71c-7.508-14.981-25.254-21.7-40.8-15.45L67.39 315.91a50.39 50.39 0 01-48.246-5.598A50.22 50.22 0 010 272c5.446-88.674 9.787-144.887 13.023-168.64C17.878 67.73 21 45.987 41.764 33.717 65.973 19.412 79.421 16 129.477 21.016c2.391.24-.028 16.05-.954 15.972-44.954-3.789-56.468-3.789-75.355 8-20.806 12.987-20.926 27.496-24.191 59.621C27.29 121.192 22.966 177.195 16 272.617a34.751 34.751 0 0012.64 24.863 34.4 34.4 0 0032.59 3.672l80.649-33.601c23.215-9.242 49.648.71 61 22.969a53.027 53.027 0 012 38.664 50.854 50.854 0 01-29.465 28.265L57.816 395.312A32.7 32.7 0 0040 424.106c-.137 3.278-.273 29.598 32.055 31.88l305.351-16.215c27.764-5.833 44.949-12.35 51.555-19.554 6.341-6.915 8.446-27.534 6.316-61.857l15.485-.91c0 23.035 7.04 68.753-14.29 79.32-13.473 6.676-25.247 15.894-59.066 19.215-.889.088-102.706 5.426-305.453 16.016z"
            fill="#4D4D4D"
          />
          <path
            fill="#4D4D4D"
            d="M128.523 36.988l.954-15.972 140.23 16.398-.957 15.969z"
          />
          <path
            d="M435.581 360V252.968c-3.581-77.436 9.364-128.3-27.081-159.122-38.49-32.55-101.016-40.463-146.286-40.463l4.526-15.746c32.26 0 104.639 3.562 153.662 45.64 26.793 22.998 31.474 71.357 30.36 156.723.472 30.436.472 75.12 0 134.054-9.13-2.556-14.19-7.24-15.18-14.054z"
            fill="#4D4D4D"
          />
          <animated.path
            style={{
              transform: rotate2.interpolate(deg => `rotate(${deg})`)
            }}
            className="cog"
            fill="#D53E5F"
            d="M97.602 12.055L109.434 2.8l21.375 8.855 1.84 14.93 11.566 11.566L159.145 40 168 61.367l-9.258 11.848v16.387L168 101.434l-8.855 21.375-14.93 1.84-11.567 11.566-1.84 14.93L109.435 160l-11.832-9.258H81.215L69.367 160 48 151.145l-1.84-14.93-11.574-11.567-14.93-1.84-8.855-21.374 9.254-11.832V73.215L10.8 61.367 19.656 40l14.93-1.84 11.566-11.574L48 11.656l21.367-8.855 11.848 9.254z"
          />
          <path
            d="M122.633 81.398c0 18.356-14.88 33.235-33.235 33.235-18.351 0-33.23-14.88-33.23-33.235 0-18.351 14.879-33.23 33.23-33.23 18.356 0 33.235 14.879 33.235 33.23z"
            fill="#FFCD34"
          />
          <animated.path
            style={{
              transform: rotate.interpolate(deg => `rotate(${deg})`)
            }}
            className="cog"
            fill="#FF5943"
            d="M333.742 50.55L358.91 24l22.168 7.2 4.754 36.28 15.152 11.008 36-6.687 13.696 18.855-17.504 32.145 5.793 17.816L472 156.344v23.312l-33.031 15.727-5.793 17.816 17.48 32.145-13.695 18.855-36-6.687-15.152 11.008-4.73 36.28-22.169 7.2-25.168-26.55h-18.734L289.84 312l-22.168-7.2-4.75-36.28-15.152-11.008-36 6.687-13.7-18.855 17.48-32.145-5.788-17.816-33.035-15.727v-23.312l33.035-15.727 5.789-17.816-17.48-32.145 13.699-18.855 36 6.687 15.152-11.008 4.75-36.28L289.84 24l25.168 26.55z"
          />
          <animated.path
            style={{
              transform: rotate3.interpolate(deg => `rotate(${deg})`)
            }}
            className="cog"
            fill="#FFCD34"
            d="M440.367 353.496l25.375 7.2 5.531 16.616-16 20.977-1.847 9.008 6.398 25.598-11.586 13.09-26.16-3.368-8.726 2.903-18.938 18.402-17.148-3.524L367.098 436l-6.883-6.113-25.375-7.2-5.527-16.617 16-20.972 1.847-9.008-6.398-25.602 11.62-13.097 26.161 3.37 8.73-2.906 18.934-18.398 17.152 3.52 10.168 24.343z"
          />
          <path
            d="M432 392c0 17.672-14.328 32-32 32s-32-14.328-32-32 14.328-32 32-32 32 14.328 32 32z"
            fill="#D53E5F"
          />
          <path
            d="M392 168c0 39.766-32.234 72-72 72s-72-32.234-72-72 32.234-72 72-72 72 32.234 72 72z"
            fill="#FFCD34"
          />
          <path
            d="M360 168c0 22.09-17.91 40-40 40s-40-17.91-40-40 17.91-40 40-40 40 17.91 40 40z"
            fill="#D53E5F"
          />
          <path
            d="M472 16c0 8.836-7.164 16-16 16s-16-7.164-16-16 7.164-16 16-16 16 7.164 16 16zM216 8a8 8 0 11-16 0 8 8 0 0116 0z"
            fill="#FBCC34"
          />
        </g>
      </svg>
    </div>
  )
}

export default Gear
