import React from "react";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../routes";

const Link = ({ to, onClick, path, ...props }) => {
  const navigate = useNavigate();
  path = path || getPath(to) || to;
  const _onClick = (e) => {
    onClick && onClick(e);
    if (!props.target) {
      e.preventDefault();
      navigate(path);
    }
  };

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={path} onClick={_onClick} {...props} />;
};

export default Link;
