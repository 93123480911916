import React from "react";

const SocialMediaIcons = () => {
  return (
    <ul className="list-inline">
      <li className="list-inline-item px-2">
        <a
          href="https://www.instagram.com/amiinp0r1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="socicon socicon-instagram icon-xsmall text-secondary" />
        </a>
      </li>
      <li className="list-inline-item px-2">
        <a
          href="https://www.facebook.com/amiinporn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="socicon socicon-facebook icon-xsmall text-secondary" />
        </a>
      </li>
      <li className="list-inline-item px-2">
        <a
          href="https://twitter.com/amiinporn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="socicon socicon-twitter icon-xsmall text-secondary" />
        </a>
      </li>
    </ul>
  );
};

export default SocialMediaIcons;
