import React from 'react'
import { useSpring, animated } from 'react-spring'

const Flash = ({ duration }) => {
  const props = useSpring({ r: 0, from: { r: 1 } })
  const r = props.r.interpolate({
    range: [0, 0.2, 1],
    output: [0, 1, 0]
  })

  return (
    <animated.svg className="flash" width="100%" height="100%">
      <animated.rect
        style={{ fillOpacity: r }}
        x={0}
        y={0}
        width="100%"
        height="100%"
        fill="white"
      />
    </animated.svg>
  )
}

export default Flash
