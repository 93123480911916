import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { trackEvent } from "../googleAnalytics";

import zeit from "../../images/social-proof/zeit.svg";
import kronen from "../../images/social-proof/kronen.svg";
import ntv from "../../images/social-proof/ntv.svg";
import golem from "../../images/social-proof/golem.svg";
import jornaldenoticias from "../../images/social-proof/jornaldenoticias.jpg";
import changeorg from "../../images/social-proof/changeorg.svg";
import bild from "../../images/social-proof/bild.svg";
import tvnow from "../../images/social-proof/tvnow.svg";
import ykollektiv from "../../images/social-proof/y-kollektiv.svg";
import { useTranslation } from "react-i18next";

const logos = [
  {
    url:
      "https://www.zeit.de/hamburg/2020-06/non-consensual-porn-hilfe-geschaedigte-startup",
    label: "zeit",
    src: zeit,
  },
  {
    url: "https://change.org/notyourporn",
    label: "changeorg",
    src: changeorg,
  },
  {
    url:
      "https://www.noticiasmagazine.pt/2021/am-i-in-porn-um-site-contra-a-pornografia-de-vinganca/estilos/263018/",
    label: "jornaldenoticias",
    src: jornaldenoticias,
  },
  {
    url: "https://www.krone.at/2210507",
    label: "kronen",
    src: kronen,
  },
  {
    url:
      "https://www.bild.de/bild-plus/digital/internet/internet/rachepornos-peinliche-fotos-wie-durchsuche-ich-das-netz-nach-mir-selbst-67397358.bild.html",
    label: "bild",
    src: bild,
  },
  {
    url:
      "https://www.tvnow.de/filme/phaenomen-racheporno-blossgestellt-und-abgezockt-19259",
    label: "ntv",
    src: ntv,
  },
  // {
  //  url: "https://fink.hamburg/2020/07/non-consensual-porn-da-gehen-leute-dran-kaputt/",
  //  label: "fink",
  //  src: fink,
  // },
  // {
  //   url: null,
  //   label: "funk",
  //   src: funk,
  // },
  {
    url: null,
    label: "golem",
    src: golem,
  },
  {
    url: "https://www.youtube.com/watch?v=TVCOPF9CLN0",
    label: "y-kollektiv",
    src: ykollektiv,
  },
  {
    url:
      "https://www.tvnow.de/filme/phaenomen-racheporno-blossgestellt-und-abgezockt-19259",
    label: "tvnow",
    src: tvnow,
  },
  // {
  //  url: null,
  //  label: "saarbruecker",
  //  src: saarbruecker,
  // },
];

const SocialProof = () => {
  const [t] = useTranslation();

  const onClick = (label) => () => {
    trackEvent({
      category: "SocialProof",
      action: "Click",
      label,
    });
  };

  return (
    <Container className="pt-2 d-flex flex-column align-items-center mb-5">
      <p className="h5">
        <strong>{t("socialProof.knownFrom")}</strong>
      </p>
      <Row className="align-items-center justify-content-center">
        {logos.map((logo, idx) => (
          <div key={idx} className="col-4 col-sm-3 col-md-2 col-lg">
            <a
              className="my-2 p-3 p-sm-2 p-lg-2 d-block"
              onClick={logo.url && onClick(logo.label)}
              target="_blank"
              rel="noopener noreferrer"
              href={logo.url}
            >
              <img
                className="social-proof-logo"
                alt={`${logo.label} logo`}
                src={logo.src}
              />
            </a>
          </div>
        ))}
      </Row>
    </Container>
  );
};

export default SocialProof;
