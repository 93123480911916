import React, { useEffect, useState } from "react";
import Legal from "./legal";
import Address from "./address";
import AddressForm from "./addressForm";
import Description from "./description";
import Submission from "./submission";
import { useCountryRestriction } from "../../countryRestrictor";
import { trackFb } from "../../../helpers/facebookTracking";

import { trackEvent } from "../../googleAnalytics";
import {
  curry,
  compose,
  set,
  apply,
  equals,
  map,
  cond,
  is,
  path,
  lensIndex,
  lensProp,
  T,
  __,
} from "ramda";
import usePrice from "../../../hooks/usePrice";

const getAddressData = (rawData) =>
  path(["purchase_units", "0", "shipping", "address"], rawData);
const getCustomerData = (rawData) => ({
  email_address: path(["payer", "email_address"], rawData),
  first_name: path(["payer", "name", "given_name"], rawData),
  last_name: path(["payer", "name", "surname"], rawData),
});

const updateIn = curry((path, val, obj) =>
  compose(
    set(__, val, obj),
    apply(compose),
    map(
      cond([
        [is(Number), lensIndex],
        [T, lensProp],
      ])
    )
  )(path)
);

const OrderConfirmation = ({
  order,
  onSubmit,
  image,
  isSubmiting,
  isSubmitError,
}) => {
  const { availableCountries } = useCountryRestriction();
  const [isEditing, setEditing] = useState(false);
  const { value: price, currency_code } = usePrice();
  const [custom, setCustom] = useState({
    payer: order.payer,
    purchase_units: order.purchase_units,
  });
  const [termsOfService, setTermsOfService] = useState(false);
  const [termsOfServiceValidated, setTermsOfServiceValidated] = useState(false);
  const [cancellationPeriod, setCancellationPeriod] = useState(false);
  const [
    cancellationPeriodValidated,
    setCancellationPeriodValidated,
  ] = useState(false);
  const [countryValidated, setCountryValidated] = useState(false);

  const [imageValidated, setImageValidated] = useState(false);
  useEffect(() => {
    if (image) setImageValidated(false);
  }, [image]);

  const [newsletterConsent, setNewsletterConsent] = useState(false);

  const onEdit = (e) => {
    trackEvent({
      category: "Order",
      action: "Click",
      label: "edit_address",
    });

    setEditing(true);
  };

  const collectFormData = (form, prevData) =>
    [...form.elements].reduce((acc, cur) => {
      const path = cur.name.split(".");
      return updateIn(path, cur.value, acc);
    }, prevData);

  const onAddressChange = (data) => {
    const { payer, shipping } = collectFormData(data);

    if (payer.email_address !== custom.payer.email_address) {
      trackEvent({
        category: "Order",
        action: "Change",
        label: "email",
      });
    }
    if (!equals(shipping.address, custom.purchase_units[0].shipping.address)) {
      trackEvent({
        category: "Order",
        action: "Change",
        label: "address",
      });
    }

    setCustom({
      payer,
      purchase_units: [{ shipping }],
    });
    setCountryValidated(false);
    setEditing(false);
  };

  const onLegalChange = ({
    cancellationPeriod,
    termsOfService,
    newsletterConsent,
  }) => {
    if (termsOfService) {
      setTermsOfServiceValidated(false);
    }
    if (cancellationPeriod) {
      setCancellationPeriodValidated(false);
    }

    setTermsOfService(termsOfService);
    setCancellationPeriod(cancellationPeriod);
    setNewsletterConsent(newsletterConsent);
  };

  const onBuy = () => {
    const isAvailableCountry = !!availableCountries[
      getAddressData(custom).country_code
    ];
    const isTermsAccepted = termsOfService === true;
    const isCancellationPeriod = cancellationPeriod === true;

    if (!isTermsAccepted) {
      setTermsOfServiceValidated(true);
    }
    if (!isCancellationPeriod) {
      setCancellationPeriodValidated(true);
    }
    if (!isAvailableCountry) {
      setCountryValidated(true);
    }
    if (!image) {
      setImageValidated(true);
    }
    if (
      !isTermsAccepted ||
      !isCancellationPeriod ||
      !isAvailableCountry ||
      !image
    ) {
      trackEvent({
        category: "Order",
        action: "Submit Error",
        label:
          !isTermsAccepted && isAvailableCountry
            ? "no terms"
            : isTermsAccepted && !isAvailableCountry
            ? "bad country"
            : "no terms, bad country",
      });
      return;
    }

    const data = {
      custom: JSON.stringify(custom),
      payment_provider_order_id: order.id,
      general_updates: newsletterConsent ? "1" : "0",
      terms_of_service: termsOfService ? "1" : "0",
      cancellation_period: cancellationPeriod ? "1" : "0",
    };

    onSubmit(data);
  };

  useEffect(() => {
    trackFb("track", "AddPaymentInfo");
  }, [currency_code, price]);

  const customer = getCustomerData(custom);
  const address = getAddressData(custom);

  return (
    <div className="payment-box">
      <AddressForm
        address={address}
        customer={customer}
        show={isEditing}
        onSave={onAddressChange}
        onCancel={() => setEditing(false)}
      />
      <Address address={address} customer={customer} onEdit={onEdit} />
      <Description />
      <Legal validated={termsOfServiceValidated} onChange={onLegalChange} />
      <Submission
        onSubmit={onBuy}
        isSubmiting={isSubmiting}
        isSubmitError={isSubmitError}
        legalInvalid={termsOfServiceValidated || cancellationPeriodValidated}
        countryInvalid={countryValidated}
        imageInvalid={imageValidated}
      />
    </div>
  );
};

export default OrderConfirmation;
