export const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
  if (typeof stroke === 'undefined') {
    stroke = true
  }
  if (typeof radius === 'undefined') {
    radius = 5
  }
  if (typeof radius === 'number') {
    radius = { tl: radius, tr: radius, br: radius, bl: radius }
  } else {
    var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 }
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side]
    }
  }
  ctx.beginPath()
  ctx.moveTo(x + radius.tl, y)
  ctx.lineTo(x + width - radius.tr, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
  ctx.lineTo(x + width, y + height - radius.br)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height)
  ctx.lineTo(x + radius.bl, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
  ctx.lineTo(x, y + radius.tl)
  ctx.quadraticCurveTo(x, y, x + radius.tl, y)
  ctx.closePath()
  if (fill) {
    ctx.fill()
  }
  if (stroke) {
    ctx.stroke()
  }
}

const DETECTION_BOX_COLOR = 'rgba(255, 255, 255, 0.75)'
const DETECTION_BOX_PADDING = 10
const NATIVE_RES = 512
const RADIUS = 30
const LINE_WIDTH = 3
export const drawResult = ({ canvas, result }) => {
  if (!canvas) {
    return
  }

  const {
    box: { left, top, width, height }
  } = result

  const context = canvas.getContext('2d')
  const diagonalRes = Math.sqrt(
    canvas.width * canvas.width + canvas.height * canvas.height
  )
  const actualLineWidth = Math.max(1, LINE_WIDTH * (diagonalRes / NATIVE_RES))
  const actualRadius = Math.max(1, RADIUS * (diagonalRes / NATIVE_RES))
  const actualPadding = Math.max(
    1,
    DETECTION_BOX_PADDING * (diagonalRes / NATIVE_RES)
  )
  context.lineWidth = actualLineWidth
  context.strokeStyle = DETECTION_BOX_COLOR
  roundRect(
    context,
    left - actualPadding,
    top - actualPadding,
    width + 2 * actualPadding,
    height + 2 * actualPadding,
    actualRadius
  )
}
