import React, { useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { animated, useSpring, useTrail, useChain } from 'react-spring'

import Link from '../link'
import ButtonLink from '../buttonLink'
import MenuButton from './menuButton'
import logo from '../../images/logo.svg'

const AnimatedButtonLink = animated(ButtonLink)

const from = {
  links: {
    opacity: 0,
    transform: 'translate3d(0,-30px,0)'
  },
  cta: {
    opacity: 0,
    transform: 'translate3d(0,30px,0)'
  }
}
const to = {
  links: {
    opacity: 1,
    transform: 'translate3d(0,0,0)'
  },
  cta: {
    opacity: 1,
    transform: 'translate3d(0,0,0)'
  }
}

const Mobile = ({ links, trackClick }) => {
  const [isOpen, set] = useState(false)
  const [t] = useTranslation()

  const overlayRef = useRef()
  const linksRef = useRef()
  const ctaRef = useRef()

  const styleOverlay = useSpring({
    ref: overlayRef,
    from: { opacity: 0 },
    opacity: isOpen ? 1 : 0
  })
  const styleCta = useSpring({
    delay: isOpen ? 450 : 0,
    ref: ctaRef,
    reset: isOpen,
    immediate: !isOpen,
    config: { mass: 1, friction: 80, tension: 710 },
    from: from.cta,
    to: isOpen ? to.cta : from.cta
  })
  const styleLinks = useTrail(links.length, {
    delay: 100,
    ref: linksRef,
    reset: isOpen,
    immediate: !isOpen,
    config: { mass: 1, friction: 80, tension: 710 },
    from: from.links,
    to: isOpen ? to.links : from.links
  })

  useChain([overlayRef, linksRef, ctaRef])

  const onClick = label => {
    const trackEvent = trackClick(label)
    return () => {
      set(false)
      trackEvent()
    }
  }

  return (
    <header className="position-relative">
      {isOpen && (
        <animated.div
          style={styleOverlay}
          onClick={() => set(false)}
          className="fullscreen-overlay mobile-overlay-background"></animated.div>
      )}
      <div className={`mobile-container py-4 ${isOpen ? 'shadow' : ''}`}>
        <Container>
          <div className="position-relative">
            <Link to="start" onClick={onClick('logo')}>
              <img className="logo logo-dark" alt="Logo" src={logo} />
            </Link>
            <MenuButton isOpen={isOpen} onClick={() => set(!isOpen)} />
          </div>
          {isOpen && (
            <animated.div className="py-3 mobile-overlay">
              <Container>
                {links.map(({ to, i18nKey }, index) => (
                  <animated.div
                    key={to}
                    style={styleLinks[styleLinks.length - index - 1]}>
                    <Link
                      onClick={onClick(to)}
                      className="border-top d-block py-3"
                      to={to}>
                      {t(i18nKey)}
                    </Link>
                  </animated.div>
                ))}
                <div>
                  <AnimatedButtonLink
                    style={styleCta}
                    onClick={onClick('start')}
                    className="btn-block"
                    to="start">
                    {t('navigation.start')}
                  </AnimatedButtonLink>
                </div>
              </Container>
            </animated.div>
          )}
        </Container>
      </div>
    </header>
  )
}

export default Mobile
