import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const FaqSearch = ({ onChange, value }) => {
  const [t] = useTranslation('faq')
  return (
    <div className="input-group input-group-lg">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <FontAwesomeIcon icon="search" />
        </span>
      </div>
      <input
        type="text"
        autoFocus
        value={value}
        onChange={onChange}
        className="form-control"
        placeholder={t('placeholder')}
      />
    </div>
  )
}

export default FaqSearch
