import React, { useState } from "react";

import ReactDOM from "react-dom";
import Spinner from "react-bootstrap/Spinner";
import { trackEvent } from "../googleAnalytics";
import usePrice from "../../hooks/usePrice";
import { useEffect } from "react";

const PAYPAL_ID = "paypalScript";
const PAYPAL_CLIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "AYg_CGAIPb9amhVoP3Ifb86gM5cOG6ZunyaRTcgQhk2d01c5HLTuS050XYR7NOArtkY1qSnd1lEodwWu"
    : "AQ7XwP4pR6q713_KQi9IvzwgCadV37UaT_6U6RSvOaHvH9aQUtfcsT26crCLAwtwpgMlvvbbLi1zuV9U";
const PAYPAL_URL = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&intent=authorize&commit=false&disable-funding=card`;

const Loading = () => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <div>
      <Spinner className="mb-2" animation="border" />
    </div>
  </div>
);

const PayPal = ({ onAuthorize, show }) => {
  const [payPalProcessingStatus, setPayPalStatus] = useState(null);
  const { currency_code, value: price } = usePrice();
  const [button, setButton] = useState();
  const [isLoaded, setLoaded] = useState(false);

  const createOrder = (_, actions) => {
    trackEvent({
      category: "Order",
      action: "PayPal Start",
    });

    setPayPalStatus("waiting");
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code,
            value: String(price),
          },
        },
      ],
    });
  };

  const onApprove = (_, actions) => {
    setPayPalStatus("authorizing");
    return actions.order
      .authorize()
      .then(function(authorization) {
        trackEvent({
          category: "Order",
          action: "PayPal Complete",
        });

        onAuthorize(authorization);
      })
      .catch((error) => {
        trackEvent({
          category: "Order",
          action: "PayPal Error",
          label: JSON.stringify(error),
        });
      });
  };

  const onCancel = () => {
    trackEvent({
      category: "Order",
      action: "PayPal Abort",
    });

    setPayPalStatus(null);
  };

  useEffect(() => {
    if (currency_code) {
      if (isLoaded) {
        const PayPalButton = global.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        setButton(
          <PayPalButton
            createOrder={createOrder}
            onApprove={onApprove}
            onCancel={onCancel}
          />
        );
      } else {
        const scriptEl = document.getElementById(PAYPAL_ID);
        if (!scriptEl) {
          const script = document.createElement("script");
          script.src = PAYPAL_URL + `&currency=${currency_code}`;
          script.id = PAYPAL_ID;

          document.body.appendChild(script);
          script.onload = () => {
            setLoaded(true);
          };
        }
      }
    }

    // eslint-disable-next-line
  }, [isLoaded, currency_code]);

  return (
    <div>
      <div
        style={{
          display:
            payPalProcessingStatus || !show || !button ? "none" : "block",
        }}
      >
        {button}
      </div>
      {payPalProcessingStatus && <Loading status={payPalProcessingStatus} />}
    </div>
  );
};

export default PayPal;
