/* globals XMLHttpRequest */

const useImageUpload = ({
  onStart,
  onProgress,
  onComplete,
  onAbort,
  onError,
  url
}) => {
  return formData => {
    const xhr = new XMLHttpRequest()

    const onLoad = () => {
      if (xhr.status > 201) {
        onError(xhr)
      } else {
        onComplete(xhr)
      }
    }

    onStart && onStart()
    xhr.upload.addEventListener('progress', onProgress, false)
    xhr.addEventListener('load', onLoad, false)
    xhr.addEventListener('error', onError, false)
    xhr.addEventListener('abort', onAbort, false)
    xhr.open('POST', url)
    xhr.withCredentials = true
    xhr.responseType = 'json'
    xhr.send(formData)
  }
}

export default useImageUpload
