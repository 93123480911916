/* globals XMLHttpRequest */
import React, { useContext, useState } from "react";
import { getRoute } from "../../../lib/apiRoutes";
import useHelpline from "../../hooks/useHelpline";

import { setDimension } from "../googleAnalytics";
export const Context = React.createContext({
  isAvailable: null,
  country: null,
  availableCountries: null,
});

const isReactSnap = navigator.userAgent === "ReactSnap";

const getAvailableCountries = async (cb) =>
  cb(
    await fetch(getRoute("countries"), {
      method: "get",
      mode: "cors",
      credentials: "include",
      headers: { "content-type": "application/json" },
    })
  );

const getGeoHeader = (availableCountries, cb) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    const isAvailable = true;
    const geoHeader = "HU";
    if (!isAvailable) {
      setDimension("dimension1", geoHeader);
    }

    cb(undefined, { isAvailable, country: geoHeader });
    return;
  }

  const request = new XMLHttpRequest();
  request.open("HEAD", window.location.origin);
  request.send();
  request.onload = function() {
    let geoHeader = request.getResponseHeader("cloudfront-viewer-country");
    geoHeader = geoHeader && geoHeader.toUpperCase();

    const isAvailable = geoHeader ? availableCountries[geoHeader] : true;

    if (!isAvailable) {
      setDimension("dimension1", geoHeader);
    }

    cb(undefined, { isAvailable, country: geoHeader });
  };
};

const CountryRestrictor = ({ children }) => {
  const [initial, setInitial] = useState(true);
  const [values, set] = useState({});
  const { isHelpline } = useHelpline();

  if (initial && !isReactSnap) {
    if (true || isHelpline) {
      set({ isAvailable: true });
    } else {
      getAvailableCountries(async (response) => {
        const availableCountries = await response.json();
        getGeoHeader(availableCountries, (error, values) => {
          if (!error) {
            set({
              ...values,
              availableCountries,
            });
          }
        });
      });
    }

    setInitial(false);
  }

  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export const useCountryRestriction = () => {
  const { isAvailable = false, country, availableCountries = {} } = useContext(
    Context
  );

  return { isAvailable, country, availableCountries };
};

export default CountryRestrictor;
