import React from 'react'
import { Col, Row } from 'react-bootstrap'

import anonymous from './anonymous.svg'
import help from './help.svg'
import precise from './precise.svg'
import search from './search.svg'
import upload from './upload.svg'

const images = {
  anonymous,
  help,
  precise,
  search,
  upload
}

const Feature = ({ index, image, title, children }) => {
  const rightImage = !(index % 2 !== 0)
  return (
    <Row className="my-5 py-3 py-md-2 py-lg-0 text-center text-md-left align-items-center">
      <Col
        md={6}
        className={`mb-4 text-center ${rightImage && 'order-md-last'}`}>
        <Row className="justify-content-center align-items-center">
          <Col xs={8}>
            <img src={images[image]} alt="" />
          </Col>
        </Row>
      </Col>
      <Col>
        <h4 className={`mb-3 ${rightImage && 'text-md-right'}`}>{title}</h4>
        <p className={`lead ${rightImage && 'text-md-right'}`}>{children}</p>
      </Col>
    </Row>
  )
}

export default Feature
