import { find, filter, whereEq } from "ramda";
import { languages, defaultLanguage } from "./i18n";

export const buildRoutes = (i18next, types) =>
  Object.keys(languages).reduce(
    (routes, language) =>
      routes.concat(
        types.map(({ key, render, ...props }) => {
          const prefix = language === defaultLanguage ? "" : `/${language}`;
          const t = i18next.getFixedT(language, "routes");
          return {
            ...props,
            language,
            key,
            path: prefix + t(key),
          };
        })
      ),
    []
  );

class RouteHandler {
  _routes = [];

  init({ language, routes }) {
    this._routes = routes;
    this._language = language;
  }

  getRoutes() {
    return this._routes;
  }

  getRoute(key) {
    const route = find(whereEq({ key, language: this._language }))(
      this._routes
    );

    if (!route && this._routes.length > 0) {
      console.error("Route not found for key: ", key);
    }
    return route || "";
  }

  getPath(key, params = {}) {
    const replaceParam = (acc, key) => acc.replace(`:${key}`, params[key]);

    return Object.keys(params).reduce(replaceParam, this.getRoute(key).path);
  }

  getAllRoutesWhere(props) {
    return this._routes ? filter(whereEq(props))(this._routes) : [];
  }

  getRoutesWhere(props) {
    return this.getAllRoutesWhere({
      ...props,
      language: this._language,
    });
  }
}

export const routeHandler = new RouteHandler();

export const getPath = routeHandler.getPath.bind(routeHandler);
export const getAllRoutesWhere = routeHandler.getAllRoutesWhere.bind(
  routeHandler
);
export const getRoutesWhere = routeHandler.getRoutesWhere.bind(routeHandler);
