import React from "react";
import { Trans, useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { isHelpline } from "../../hooks/useHelpline";

const icon = require("./videos.svg");

const Head = ({ videosLength, highestPercentage, email }) => {
  const onNumbersHint = (e) => {
    e.preventDefault();
  };
  const [t] = useTranslation();

  return (
    <div className="text-center">
      <div className="mt-5">
        <img width="200" src={icon} alt="" />
      </div>
      <h4 className="font-weight-normal text-center mt-4 mb-4">
        {highestPercentage > 1 ? (
          <Trans
            i18nKey="resultListingHead.headline"
            videosLength={videosLength}
            highestPercentage={highestPercentage}
          >
            Find below the
            <strong>{{ videosLength }} most relevant videos </strong> with up to
            <br />
            <strong>{{ highestPercentage }}% probability</strong> of matching
            your face
          </Trans>
        ) : (
          <Trans i18nKey="resultListingHead.noMatch">
            We haven't found any match.
            <br /> Here are the most similar results.
          </Trans>
        )}
        {!isHelpline && (
          <div className="mt-3">
            <small>
              <Trans i18nKey="resultListingHead.resultViaEmail" email={email}>
                We'll also send you the results to <strong>{{ email }}</strong>.
              </Trans>
            </small>
          </div>
        )}
      </h4>
      <div className="bar bg-gradient p-3">
        <div className="lead">
          <strong>{t("resultListingHead.markResults")}</strong>
          <br /> <small>{t("resultListingHead.itemShows")}</small>
        </div>
        <div className="mt-3">
          <OverlayTrigger
            placement={"bottom"}
            className="mx-3"
            overlay={
              <Tooltip id="tooltip-numbers">
                {t("resultListingHead.numbersHint")}
              </Tooltip>
            }
          >
            <span
              className="d-block d-sm-inline-block mx-2"
              href="#"
              onClick={onNumbersHint}
            >
              {t("resultListingHead.numbers")}
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip id="tooltip-numbers">
                {t("resultListingHead.duckduckgoHint")}
              </Tooltip>
            }
          >
            <span
              className="d-block d-sm-inline-block mx-2"
              href="#"
              onClick={onNumbersHint}
            >
              {t("resultListingHead.duckduckgo")}
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default Head;
