import { library } from '@fortawesome/fontawesome-svg-core'
import { faFlag, faSmile, faSquare } from '@fortawesome/free-regular-svg-icons'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import {
  faEdit,
  faImage,
  faQuestionCircle,
  faCheck,
  faCog,
  faDotCircle,
  faExternalLinkAlt,
  faGlassCheers,
  faSearch,
  faArrowRight,
  faPlay,
  faPhotoVideo,
  faRedo,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowRight,
  faCheck,
  faCog,
  faEdit,
  faExternalLinkAlt,
  faDotCircle,
  faFlag,
  faQuestionCircle,
  faGlassCheers,
  faImage,
  faPaypal,
  faPhotoVideo,
  faPlay,
  faRedo,
  faSearch,
  faSmile,
  faSquare,
  faTimes,
  faTrash
)
