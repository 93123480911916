import { useMemo, useEffect, useState } from 'react'

const createMediaQueries = breakpoints => {
  const sortedBreakpoints = Object.keys(breakpoints).sort(
    (a, b) => breakpoints[b] - breakpoints[a]
  )

  return sortedBreakpoints.map((breakpoint, index) => {
    let query = ''
    const minWidth = breakpoints[breakpoint]
    const nextBreakpoint = sortedBreakpoints[index - 1]
    const maxWidth = nextBreakpoint ? breakpoints[nextBreakpoint] : undefined

    if (minWidth >= 0) {
      query = `(min-width: ${minWidth}px)`
    }

    if (typeof maxWidth !== 'undefined') {
      if (query) {
        query += ' and '
      }
      query += `(max-width: ${maxWidth - 1}px)`
    }

    const mediaQuery = {
      breakpoint,
      maxWidth,
      minWidth,
      query
    }

    return mediaQuery
  })
}

// bootstrap 4
const config = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 }

export default function useBreakpoint() {
  const mediaQueries = useMemo(() => createMediaQueries(config), [])
  const [currentBreakpoint, setCurrentBreakpoint] = useState()

  const updateBreakpoint = ({ matches }, breakpoint) => {
    if (matches) {
      setCurrentBreakpoint(breakpoint)
    }
  }

  useEffect(() => {
    mediaQueries.forEach(({ query, ...breakpoint }) => {
      const mediaQuery = window.matchMedia(query)
      updateBreakpoint(mediaQuery, breakpoint)

      const handleChange = () => updateBreakpoint(mediaQuery, breakpoint)
      mediaQuery.addListener(handleChange)
      return () => mediaQuery.removeListener(handleChange)
    })
  }, [mediaQueries])

  return {
    down: bp => currentBreakpoint && currentBreakpoint.minWidth <= config[bp],
    up: bp => currentBreakpoint && currentBreakpoint.minWidth >= config[bp]
  }
}
