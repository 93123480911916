import React, { useState } from 'react'
import WebcamComponent from 'react-webcam'
import { prop } from 'ramda'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Flash from './flash'
import * as faceapi from 'face-api.js'
import { drawResult } from '../../helpers/canvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import useBreakpoint from '../../hooks/useBreakpoint'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user'
}

const getBox = prop('box')

const biggestDifference = ({ previousResult, result }) => {
  return getBox(previousResult) && getBox(result)
    ? ['bottom', 'top', 'left', 'right']
        .map(key => Math.abs(previousResult.box[key] - result.box[key]))
        .reduce(function(a, b) {
          return Math.max(a, b)
        })
    : 0
}

const THRESHOLD = 20
let previousResult = {}
let isBoxDrawn = false
let reqFrame = null

const ModalContainer = ({ show, onClose, children }) => {
  return (
    <Modal
      show={show}
      size="lg"
      dialogClassName="camera-modal"
      centered
      onHide={onClose}>
      <Modal.Body className="p-0 m-0">{children}</Modal.Body>
    </Modal>
  )
}
const FullscreenContainer = ({ show, children }) => {
  return show ? (
    <div className="fullscreen-overlay">
      <div className="absolute-centered webcam-fullscreen">{children}</div>
    </div>
  ) : null
}

const CameraModal = ({ show, onClose, onImageSelect }) => {
  const [t] = useTranslation()
  const { down } = useBreakpoint()
  const [hasPermission, setHasPermission] = useState(null)
  const [picture, setPicture] = useState(null)
  const [initializing, setInitializing] = useState(true)
  const ref = React.useRef(null)
  const canvasRef = React.useRef()

  const renderFaceDetection = async () => {
    if (!canvasRef.current || !ref.current) {
      return
    }

    if (!faceapi.nets.tinyFaceDetector.isLoaded) {
      await faceapi.nets.tinyFaceDetector.loadFromUri('/face-verification')
    }
    setInitializing(false)

    const context = canvasRef.current.getContext('2d')
    const videoEl = document.getElementById('webcam-video')
    const result = await faceapi.detectSingleFace(
      videoEl,
      new faceapi.TinyFaceDetectorOptions()
    )

    const isResultDifferent =
      !previousResult ||
      !previousResult.box ||
      biggestDifference({ previousResult, result }) > THRESHOLD

    try {
      if (result && result.box) {
        if ((!isBoxDrawn || isResultDifferent) && result.score >= 0.7) {
          const dims = faceapi.matchDimensions(canvasRef.current, videoEl, true)
          const resizedResult = faceapi.resizeResults(result, dims)
          drawResult({ canvas: canvasRef.current, result: resizedResult })

          isBoxDrawn = true
          previousResult = result
        }
      } else {
        context.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        )
        isBoxDrawn = false
      }
    } catch (e) {
      console.log(e)
    }

    reqFrame = window.requestAnimationFrame(renderFaceDetection)
  }

  const onCapture = React.useCallback(() => {
    const picture = ref.current.getScreenshot()
    setPicture(picture)
    window.cancelAnimationFrame(reqFrame)

    setTimeout(() => {
      onClose()
      setTimeout(() => {
        onImageSelect(picture, { type: 'camera' })
      }, 300)
    }, 2000)
  }, [ref, onClose, onImageSelect])

  const webcam = (
    <div className="webcam-container d-flex align-items-center justify-content-center">
      {hasPermission === null ||
        (initializing && <Spinner className="my-5" animation="border" />)}
      <WebcamComponent
        audio={false}
        ref={ref}
        onLoadedMetadata={renderFaceDetection}
        id="webcam-video"
        className={!hasPermission || initializing ? 'd-none' : ''}
        screenshotFormat="image/jpeg"
        onUserMedia={() => setHasPermission(true)}
        onUserMediaError={e => setHasPermission(false)}
        videoConstraints={videoConstraints}
      />
      {hasPermission === false && (
        <div>
          {t('cameraModal.blockedCamera')}
          <br />
          {t('cameraModal.see')}{' '}
          <a href="https://support.google.com/chrome/answer/114662?co=GENIE.Platform%3DDesktop&hl=en">
            https://support.google.com/chrome/answer/114662?co=GENIE.Platform%3DDesktop&hl=en
          </a>
        </div>
      )}
    </div>
  )

  const Container = down('sm') ? FullscreenContainer : ModalContainer

  return (
    <Container show={show} onClose={onClose}>
      <div>
        {picture ? (
          <div>
            <img src={picture} alt="" />
            <Flash />
          </div>
        ) : (
          webcam
        )}
        <canvas className="canvas-face" ref={canvasRef}></canvas>
      </div>
      <div className="webcam-buttons d-flex">
        <div className="d-flex align-items-center justify-content-center">
          <span
            className={`p-3 cancel-button ${
              !hasPermission || initializing ? 'disabled' : ''
            }`}
            onClick={onClose}>
            Cancel
          </span>
        </div>
        <div>
          <span
            className={`p-3 take-picture-button ${
              initializing ? 'disabled' : ''
            }`}
            onClick={onCapture}>
            <FontAwesomeIcon
              size="3x"
              fixedWidth
              icon="dot-circle"></FontAwesomeIcon>
          </span>
        </div>
        <div>
          <span></span>
        </div>
      </div>
    </Container>
  )
}

export default CameraModal
