import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import usePrice from "../../../hooks/usePrice";

const Submission = ({
  onSubmit,
  countryInvalid,
  imageInvalid,
  legalInvalid,
  isSubmiting,
  isSubmitError,
}) => {
  const [t] = useTranslation();
  const { formattedValue } = usePrice();
  return (
    <>
      <Row className="mb-3 mt-3 mt-md-1">
        <Col className="text-center text-md-right">
          <p className="lead font-weight-bold mb-0">
            {t("submission.total")}: {formattedValue}
          </p>
          <small className="d-block">{t("submission.vats")}</small>
        </Col>
      </Row>
      <Row className="justify-content-end mb-3 text-center">
        <Col md={9}>
          {legalInvalid && (
            <div className="text-danger">{t("submission.errorTos")}</div>
          )}
          {countryInvalid && (
            <div className="text-danger">{t("submission.errorCountry")}</div>
          )}
          {imageInvalid && (
            <div className="text-danger">{t("submission.errorImage")}</div>
          )}
          {isSubmitError && (
            <div className="text-danger">
              <Trans i18nKey="submission.errorTryAgain">
                Something didn't work as expected.
                <br /> Please try again.
              </Trans>
            </div>
          )}
          <Button size="lg" disabled={isSubmiting} block onClick={onSubmit}>
            {isSubmiting
              ? t("submission.btnLoading")
              : t("submission.btnSubmit")}
          </Button>
          <small>{t("submission.getResultsAfterPurchase")}</small>
        </Col>
      </Row>
      <Row>
        <Col className="mt-5">
          <hr />
          <p>
            <small>(*): {t("legal.checkboxTooltip")}</small>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Submission;
