import React, { useContext } from "react";
import { setDimension } from "../components/googleAnalytics";
import { useTranslation } from "react-i18next";
import { useCountryRestriction } from "../components/countryRestrictor/countryRestrictor";

const getFormatter = ({ currency_code = "EUR", lang }) => {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency: currency_code,
  });
};

const initialValues = {
  value: null,
  currency: null,
  symbol: null,
};
const PricingContext = React.createContext(initialValues);
export const Pricing = ({ children }) => {
  const { availableCountries, country } = useCountryRestriction();
  const priceForCountry = availableCountries[country];
  const values = priceForCountry
    ? {
        value: parseFloat(priceForCountry.value),
        currency_code: priceForCountry.currency_code,
        symbol: priceForCountry.symbol,
      }
    : initialValues;

  setDimension("dimension2", values.value);

  return (
    <PricingContext.Provider value={values}>{children}</PricingContext.Provider>
  );
};

const usePrice = () => {
  const { currency_code, symbol, value } = useContext(PricingContext);
  const { i18n } = useTranslation();
  const formatter = getFormatter({
    currency_code,
    lang: i18n.language,
  });

  const formattedValue = formatter.format(value);

  return {
    currency_code,
    formattedValue,
    symbol,
    value,
  };
};

export default usePrice;
